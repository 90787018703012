import { getCRDTItem, hasCRDTItem, sharedReducerMap } from "../multi-user/redux";
import { FRONTEND_DATA, PublishState,  YTransactionTypes } from "../../globals";
import { Y, ydoc } from "../multi-user";
import { store } from "../../index";
import morphdom from '../morphdom';
import { getDomBinding } from "./";
import _ from 'lodash';

export const domBindingsMap = new Map(); // keep track of the current dom bindings
window.domBindingsMap = domBindingsMap;

const observerMap = new Map(); // keep track of the observers

const removeDOMBinding = (pid, options = {}) => {

	if(!domBindingsMap.has(pid)) {
		// no binding to delete
		return;
	}

	// keep a reference to the binding's element
	const pageElement = domBindingsMap.get(pid).target;

	// destroy the binding and remove it from the map
	domBindingsMap.get(pid).destroy();
	domBindingsMap.delete(pid);

	// console.log('deleted DOM binding for', pid);
	// if the page's element is still in the DOM, make sure it's content is restored
	if(
		options.hasOwnProperty('originalContent')
		&& pageElement.ownerDocument.contains(pageElement)
	) {

		morphdom(pageElement, `<bodycopy>${options.originalContent || ""}</bodycopy>`, {
			childrenOnly: true
		});

	}

}

export const createDOMBinding = (pid, options = {}) => {

	// before doing anything else check to see if we already have a binding
	if(domBindingsMap.has(pid)) {
		return;
	}

	const pageElement = _.findLast(
		store.getState().frontendState.renderedPages, 
		page => {
			return page.id === pid && page.ownerDocument.contains(page)
		}
	)?.querySelector('bodycopy');

	if(
		// no page el
		!pageElement 
		// not trying to initialize a new CRDT item and nothing exists yet
		|| (options.initUsingDOM !== true && !hasCRDTItem({
			reducer: 'pages.byId',
			item: pid
		}))
	) {
		return;
	}

	const { CRDTItem } = getCRDTItem({
		reducer: 'pages.byId',
		item: pid
	});

	if(CRDTItem.get('content') instanceof Y.XmlFragment) {

		// console.log('created DOM binding from pre-existing CRDT data for', pid);
		// Create new instance if we have a fragment
		domBindingsMap.set(pid, getDomBinding(CRDTItem.get('content'), pageElement));

	} else if(options.initUsingDOM) {

		ydoc.transact(() => {
			
			// Create a new fragment
			CRDTItem.set('content', new Y.XmlFragment());
	
			// console.log('created DOM binding from string content', pid);
			
			// create a dom binding and use the initUsingDOM to 
			// inject the current HTML structure into the fragment
			domBindingsMap.set(pid, getDomBinding(CRDTItem.get('content'), pageElement, {
				initUsingDOM: true
			}));

		});

	}

}

// detect if a DOM binding's type got silently replaced with a fresh instance
ydoc.on('afterTransaction', transaction => {

	Y.iterateDeletedStructs(transaction, transaction.deleteSet, item => {

		domBindingsMap.forEach((binding, pid) => {

			if(
				binding.type
				&& binding.type._item === item
				// this is ok when discarding the doc
				&& store.getState().adminState.crdt.publishState !== PublishState.Discarding
			) {

				console.log('Detected stale type. Reloading DOM binding for', pid)

				// remove the binding using the deleted type
				removeDOMBinding(pid);

				// create a new binding using the new type
				createDOMBinding(pid);

			}

		});

	});

});

export const setupSharedDomListener = () => {

	// listen for CRDT changes
	sharedReducerMap.get('pages.byId').type.observeDeep((events, transaction) => {

		events.forEach(event => {

			if(event.target === sharedReducerMap.get('pages.byId').type) {
				// added or removed a page from the pages.byId map

				event.changes.keys.forEach((change, pageId) => {
					if(change.action === 'add') {
						createDOMBinding(pageId)
					} else if(change.action === 'delete') {
						removeDOMBinding(pageId)
					}
				});

			} else if(event.changes.keys.has('content')) {
				// if the 'content' field was directly modified

				const newValue = event.target.get('content');
				const oldValue = event.changes.keys.get('content').oldValue;

				if(
					// old value was not a fragment
					oldValue instanceof Y.XmlFragment === false
					// but now is
					&& newValue instanceof Y.XmlFragment === true
				){ 
				
					//console.log(event.target.get('id'), 'content set to Y.XmlFragment');
					createDOMBinding(event.target.get('id'));

				} else if (
					// old value was a fragment
					oldValue instanceof Y.XmlFragment === true 
					// but is not anymore
					&& newValue instanceof Y.XmlFragment === false
				) {

					// console.log(event.target.get('id'), 'content no longer a Y.XmlFragment');
					removeDOMBinding(event.target.get('id'), {
						originalContent: newValue
					})

				}

			} else {
				// if a change was inside an already existing XmlFragment

				let parentType = event.target;

				while(parentType) {

					if(parentType.constructor === Y.XmlFragment) {
						
						// found a change to an XmlFragment. Confirm it's a `content` field on a page
						const pageType = parentType.parent;

						if(
							pageType
							// the page has an id set
							&& pageType.has('id')
							// and it's content field is this XmlFragment
							&& pageType.get('content') === parentType
						) {
							// ensure we have a binding
							createDOMBinding(pageType.get('id'))
						}

						return;
					}

					// move up
					parentType = parentType.parent;
				}

			}

		});

	})
	
	let lastRenderedPages = [];

	// listen for DOM changes
	store.subscribe(() => {

		const renderedPages = store.getState().frontendState.renderedPages;


		if(renderedPages !== lastRenderedPages) {

			const mountedPages = _.difference(renderedPages, lastRenderedPages);
			const unMountedPages = _.difference(lastRenderedPages, renderedPages);

			lastRenderedPages = renderedPages;

			unMountedPages.forEach(el => {
				removeDOMBinding(el.id)
			});

			mountedPages.forEach(el => {
				createDOMBinding(el.id);
			});

		}

	});

}

