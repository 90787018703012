/**
 * @module bindings/dom
 */

/* eslint-env browser */
/* global getSelection */

import * as Y from "yjs";

import { removeDomChildrenUntilElementFound, convertTypeToDom } from './util.js'

const findScrollReference = (scrollingElement) => {
		if (scrollingElement !== null) {
				let anchor = getSelection().anchorNode;
				if (anchor == null) {
						let children = scrollingElement.children; // only iterate through non-text nodes
						for (let i = 0; i < children.length; i++) {
								const elem = children[i];
								const rect = elem.getBoundingClientRect();
								if (rect.top >= 0) {
										return { elem, top: rect.top };
								}
						}
				} else {
						/**
						 * @type {Element}
						 */
						let elem = anchor.parentElement;
						if (anchor instanceof Element) {
								elem = anchor;
						}
						return {
								elem,
								top: elem.getBoundingClientRect().top,
						};
				}
		}
		return null;
};

const fixScroll = (scrollingElement, ref) => {
		if (ref !== null) {
				const { elem, top } = ref;
				const currentTop = elem.getBoundingClientRect().top;
				const newScroll = scrollingElement.scrollTop + currentTop - top;
				if (newScroll >= 0) {
						scrollingElement.scrollTop = newScroll;
				}
		}
};

/**
 * @private
 */
export const typeObserver = function (events, transaction) {

		// don't observe own changes
		if(transaction.origin === this) {
			return;
		}

		if(transaction.local === false || transaction.origin instanceof Y.UndoManager) {

			// Stop the mutation observer when applying external edits. Otherwise
			// we'll end up in an infinite feedback loop.
			this.stopMutationObserver(true);

		}

		const scrollRef = findScrollReference(this.scrollingElement);

		events.forEach((event) => {

			const yxml = event.target
			const dom = this.typeToDom.get(yxml)

			if (dom !== undefined && dom !== false) {

				if (yxml.constructor === Y.XmlText) {

					dom.nodeValue = yxml.toString();

				} else if (event.attributesChanged !== undefined) {
					// update attributes
					event.attributesChanged.forEach(attributeName => {

						const value = yxml.getAttribute(attributeName);
						
						if (value === undefined) {
							dom.removeAttribute(attributeName);
						} else {
							dom.setAttribute(attributeName, value);
						}

					});

					/*
					 * TODO: instead of hard-checking the types, it would be best to
					 *       specify the type's features. E.g.
					 *         - _yxmlHasAttributes
					 *         - _yxmlHasChildren
					 *       Furthermore, the features shouldn't be encoded in the types,
					 *       only in the attributes (above)
					 */
					
					if (event.childListChanged && yxml.constructor !== Y.XmlHook) {
						let currentChild = dom.firstChild;

						for(let i = 0; i < yxml.length; i++) {

								const childNode = this.typeToDom.get(yxml.get(i));

								switch (childNode) {
									case undefined:
										// Does not exist. Create it.
										const node = convertTypeToDom(yxml.get(i), this.opts.document, this.opts.hooks, this);
										dom.insertBefore(node, currentChild);
										break;
									case false:
										// noop
										break;
									default:
										// Is already attached to the dom.
										// Find it and remove all dom nodes in-between.
										removeDomChildrenUntilElementFound(dom, currentChild, childNode);
										currentChild = childNode.nextSibling;
										break;
								}
						}

						removeDomChildrenUntilElementFound(dom, currentChild, null);

					}
				}
			}
		});

		fixScroll(this.scrollingElement, scrollRef);

		if(transaction.local === false || transaction.origin instanceof Y.UndoManager) {

			const event = new CustomEvent("y-dom-binding-type-changed", {
					bubbles: true,
					events
			});

			this.target.dispatchEvent(event);

			// wait a beat till all async observers had time to apply their changes
			Promise.resolve().then(() => {
				if(!this._mutationSummary.connected) {
						// restart the observer after an external change has been applied
						this.startMutationObserver();
				}
			});

		}
};
