import { DomBinding } from "./y-dom";
import { FRONTEND_DATA } from "../../globals";
import _ from 'lodash';

const attributeFilters = {
	'AUDIO-PLAYER': {
    	attributeCanBeRetained: function(node, attributeName){
			return attributeName !== 'status'
		}
	},	

	'COLUMN-SET': {
    	attributeCanBeRetained: function(node, attributeName){
			return attributeName !== 'data-column-count'
		}
	},
    'MEDIA-ITEM': {
    	attributesToFilterOut: ['slot', 'status'],
    	attributeCanBeRetained: function(node, attributeName){
			return this.attributesToFilterOut.indexOf(attributeName) === -1;
		}
    }
}


const getAllDescendants = function(node, callback) {

	// recursively finds all childnodes and fires a callback for every node found
	for (var i = 0; i < node.childNodes.length; i++) {
		callback(node.childNodes[i])
		// recursively go down the tree.
		getAllDescendants(node.childNodes[i], callback);
	}

}

let syncableMutationsDetected = false;

export const observeInlineEditorMutations = (CargoEditor) => {
	CargoEditor.events.on('mutation-start', () => {
		syncableMutationsDetected = true;
	})
}

export const getDomBinding = (yXmlFragment, domTarget, options = {}) => {

	options.nodeFilter = (node) => {

		// if the node is a child of a thumbnail index, void it unless it contains stringified metadata
		if( node.nodeName !=='SCRIPT' && node.parentElement && node.parentElement.hasAttribute('thumbnail-index') && node.parentElement.getAttribute('thumbnail-index') !== 'null'){
			return false;
		}

		// make sure cloned contents of marquee does not make it to crdt
		if( node.nodeName==='MARQUEE-INNER' && node.slot ==='contents-clone'){
			return false;
		}		

		// Only allow a subset of node types YJS can handle
		if( node.nodeType !== Node.ELEMENT_NODE && node.nodeType !== Node.TEXT_NODE && node.nodeType !== Node.COMMENT_NODE){
			console.log('nodefilter filter: unusable nodeType', node);
			return false
		}

		// node is explicitly marked as non-saveable by the editor. Ignore it.
		if(node.saveable === false) {
			// console.log('nodefilter filter: unsaveable node', node);
			return false;
		}
		
		const el = node.nodeType === Node.ELEMENT_NODE ? node : node.parentElement;

		// do not sync children of cargo-embed nodes
		if(el && el.closest('cargo-embed') && el.nodeName !== "CARGO-EMBED") {
			return false;
		}

		return true;
	}

	options.mutationFilter = mutations => {

		if(syncableMutationsDetected) {

			// reset the syncable mutations state
			syncableMutationsDetected = false;

			// allow mutations to be reported and synced
			return true;
		}

		// do not report if no user change was detected
		return false;

	}

	options.attributeFilter = (node, attribute) => {
		if ( attribute.startsWith('dynamic-') ){
			return false;
		} else if( attributeFilters[node.tagName] ){
			return attributeFilters[node.tagName].attributeCanBeRetained(node, attribute)
		} else {
			return true;
		}
	}

	options.onNewNodeAssociated = (node, type) => {

		// new nodes created by the binding should be marked as saveable, as non-saveable
		// nodes never even make it into the CRDT

		// filter out thumbnail index nodes here as well
		if( node.parentElement && node.parentElement.hasAttribute('thumbnail-index') && node.nodeName!=='SCRIPT'){

			node.setSaveable(false);
			
		} else {
			
			node.setSaveable(true);

			getAllDescendants(node, function(child){
				child.setSaveable(true);
			});
		}


	}

	options.preventMutationObserverStart = options.preventMutationObserverStart ?? !domTarget.hasAttribute('contenteditable');

	const binding = new DomBinding(yXmlFragment, domTarget, options);

	// mark all initial content as saveable, unless it was already explicitly set to non-saveable
	getAllDescendants(binding.target, function(node){
		
		if(node.hasOwnProperty('saveable') && node.saveable === false) {
			return;
		}
		
		node.setSaveable(true);
		
	});

	return binding;

}

export { setupSharedDomListener, createDOMBinding, domBindingsMap } from './dom-binding-listener';
