import { Observable } from 'lib0/observable';
import { 
	declarationHasTrailingSemicolon, 
	findEmptySpaceTillNextDeclaration, 
	findEmptySpaceTillPreviousDeclaration, 
	countLineBreaksInWhiteSpace,
	compareStringSimilarity
} from './helpers';

function _setValue(newValue) {

	if(newValue !== undefined && newValue !== null && typeof newValue !== "string") {
		// propWatcher values are always strings. Cast any new values to
		// strings so we can check equality (1 === "1" -> "1" === "1")
		newValue = String(newValue)
	}

	if(newValue === this.value) {
		// do nothing if nothing changed
		return;
	}

	//console.log('change from', this.value, 'to', newValue, this);

	// cache old value
	const oldValue = this.value;

	// clear the value
	this.value = newValue;

	// emit a change event
	this.emit('change', [this.value, oldValue, this]);

}

export default class _PropWatcher extends Observable {

	constructor(selector, propertyName, parser) {

		super();

		this.selector = selector;
		this.propertyName = propertyName;
		this.parser = parser;
	
		// defaults
		this.propWatchersToInheritFrom = null;
		this.propWatcherUsedForInheritance = null;
		this.value = null;
		this.errors = [];
		this.lastDeclarationInBlock = null;

		// bind listeners
		parser.on('afterParse', this.afterParse, this);

	}

	get isInheriting() {
		return this.propWatcherUsedForInheritance !== null;
	}

	afterParse = () => {

		if(this.propWatcherUsedForInheritance) {

			// we found a value in the CSS, this means the watcher should be
			// released from inheritance.
			if(this.parsedValue) {
				
				// remove the inheritance and proceed handling the
				// newly parsed value below.
				this.propWatcherUsedForInheritance = null;

			} else if(this.propWatcherUsedForInheritance === this.getPropWatcherToInheritFrom(null)) {
				// Make sure the watcher we are supposed to inherit from hasn't changed

				// The propwatcher is still inheriting from the same parent and
				// it's parent (onInheritedPropWatcherChange) will update this. Don't do anything here.
				return;
			}

		}

		// Propwatcher does not exist in the CSS. 
		if(!this.parsedValue) {

			this.propWatcherUsedForInheritance = this.getPropWatcherToInheritFrom(null)

			// check to see if we need to inherit
			if(this.propWatcherUsedForInheritance) {

				// set this propWatcher's value to the parent's value
				_setValue.call(this, this.propWatcherUsedForInheritance.value);

			} else if(this.value !== null) {
				// Do nothing except clear the value if it was set before.
				_setValue.call(this, null);
			}

			return;

		}

		if(this.overriddenByShorthandValue) {

			if(this.value !== this.overriddenByShorthandValue.content) {
				_setValue.call(this, this.overriddenByShorthandValue.content);
			}

		} else {

			if(this.value !== this.parsedValue.content) {
				_setValue.call(this, this.parsedValue.content);
			}

		}

	}

	getPropWatcherToInheritFrom = (ownValue) => {

		if(!this.propWatchersToInheritFrom) {
			return null;
		}

		let matchWithInheritedPropertyValue;

		for(let i = 0; i < this.propWatchersToInheritFrom.length; i++) {

			if(
				// the parent has a value
				this.propWatchersToInheritFrom[i].value !== null
				&& (
					// and that value is either equal to ours
					this.propWatchersToInheritFrom[i].value === ownValue
					// or we don't have a value ourselves
					|| ownValue === null
				)
			) {

				if(this.propWatchersToInheritFrom[i].propWatcherUsedForInheritance) {
					// this parent is not directly set but inheriting. Only
					// use if no match with it's own value is found
					matchWithInheritedPropertyValue = this.propWatchersToInheritFrom[i];

					// this might turn out to be problematic, but go up the inheritance
					// tree till we find the original source and use that
					while(matchWithInheritedPropertyValue.propWatcherUsedForInheritance) {
						matchWithInheritedPropertyValue = matchWithInheritedPropertyValue.propWatcherUsedForInheritance;
					}

				} else {
					// directly return
					return this.propWatchersToInheritFrom[i];
				}

			}

		}

		// if nothing direct found, return the indirect match if any found
		return matchWithInheritedPropertyValue || null;

	}

	setValue(newValue, options = {}) {

		// Manually setting an inherited propWatcher requires a check to
		// see if we're still eligible to inherit from a parent when using that new value
		this.propWatcherUsedForInheritance = this.getPropWatcherToInheritFrom(newValue);

		// if we have a parent to inherit from
		if(this.propWatcherUsedForInheritance && options.ignoreInheritance !== true) {

			// we're not affected by a shorthand in the same rule
			if(!this.affectedByShorthandValues || Object.keys(this.affectedByShorthandValues).length === 0) {

				let longhandPropertiesAffectingParentPropWatcher;

				if(this.propWatcherUsedForInheritance.propertyName === this.propertyName) {

					// if the parent watcher is the same property, make sure we check and override all of it's longhands.
					// For example: we have 'padding' locally but the inherited padding has ben overloaded by a 'padding-right' and 'padding-top'
					// we need to locally set a 'padding-right' and 'padding-top' if they don't match the new value we're setting.

					longhandPropertiesAffectingParentPropWatcher = Object.keys(this.propWatcherUsedForInheritance.affectedByLonghandValues || {});

				} else if(this.propWatcherUsedForInheritance.affectedByLonghandValues?.hasOwnProperty(this.propertyName)) {

					// if the parent watcher targets a different property, check to see if the parent property is affected by 
					// the same property we're currently trying to remove. 
					//
					// For example, if this watcher is for 'padding-left', and we inherit from 'padding',
					// make sure that inherited padding isn't overloaded by a 'padding-left' in it's own block. If so, we want to
					// insert a padding-left locally to ensure we don't start inheriting the parent's 'padding-left' value in case it's different from the 
					// value we're setting here.

					longhandPropertiesAffectingParentPropWatcher = [this.propertyName];
				}

				const inheritedValue = this.propWatcherUsedForInheritance.value;

				// set this propWatcher's value to the parent's value
				_setValue.call(this, inheritedValue);

				// and remove it's value from the CSS. 
				this.removeCSSDeclaration();

				if(longhandPropertiesAffectingParentPropWatcher && longhandPropertiesAffectingParentPropWatcher.length > 0) {

					// when removing a shorthand, make sure we check for remaining longhand values
					// that still need to be overridden to maintain the correct value. If we delete
					// a "padding: 2rem;" value, but the parent rule still has a "padding-left: 1rem" remaining,
					// we need to locally set "padding-left: 2rem" otherwise we will start inheriting the incorrect
					// parent padding-left of 1rem

					// only run this after all current mutations have run. This is due to the nature of how the admin sets
					// all padding + padding longhands every time. Not ideal, we should fix that and only set affected values
					// and let this library figure out how to deal with the other properties.
					this.parser.once('allMutationsComplete', () => {

						this.parser.mutateSharedType(() => {
							longhandPropertiesAffectingParentPropWatcher.forEach(parentLonghandProperty => {

								// create a temporary propWatcher
								const tempPropWatcher = this.parser.getPropWatcher(this.selector, parentLonghandProperty);
								const tempParentPropWatcher = this.propWatcherUsedForInheritance?.parser?.getPropWatcher(this.propWatcherUsedForInheritance.selector, parentLonghandProperty);

								tempPropWatcher?.inheritFrom(tempParentPropWatcher);
								
								// set & destroy
								tempPropWatcher?.setValue(inheritedValue);
								tempPropWatcher?.destroy();
								tempParentPropWatcher?.destroy();

							})

						});

					})
					
				}

			} else {

				// set this propWatcher's value to the parent's value
				_setValue.call(this, this.propWatcherUsedForInheritance.value);

				// and remove it's value from the CSS. 
				this.removeCSSDeclaration();

			}

			// we're done.
			return;

		}

		// Manually clear the property from the CSS
		if(newValue === null) {

			_setValue.call(this, null);

			// we have a parsed value, so this propWatcher exists in the current CSS
			if(this.parsedValue) {
				// remove it
				this.removeCSSDeclaration();
			}

			return;

		}

		if(typeof newValue !== "string") {
			// yText.insert will silently fail if we pass it anything other than a string
			newValue = String(newValue)
		}

		this.parser.mutateSharedType(yText => {

			if(!this.parsedRule) {
				// No rule can be found in the CSS. Insert the entire block (selector + property)

				// We're setting our own value so there's no inheritance happening anymore at this point, 
				// but we can still figure out this propWatcher's parent context by looking at what propWatcher
				// we would inherit from if our value wasn't set yet.
				const parentPropWatcher = this.getPropWatcherToInheritFrom(null);

				// insert at the end of the document by default
				let insertPosition = yText.length;
				let newLinePrefix = '';

				if(parentPropWatcher && parentPropWatcher.parsedRule && parentPropWatcher.errors.length === 0) {

					// This propWatcher has a relationship to a parent propWatcher. To maintain semantic context we inject the 
					// new rule immediately after the block this value is inherited from
					insertPosition = parentPropWatcher.parsedRule.end + 1;

				} else {

					// try matching based on selector similarity
					const parsedRules = this.parser.getParsedRules();

					let bestSelectorOverlap = 0;
					let bestMatchedRule;

					parsedRules.forEach(rule => {

						const selector = rule.parsedSelectors.map(selector => selector.content).join(' ');
						const selectorSimilarity = compareStringSimilarity(this.selector, selector);

						if(
							// this selector matches over the minimum requirement
							selectorSimilarity > 0.7 
							// and it matches better than anything before it
							&& selectorSimilarity > bestSelectorOverlap
						) {
							bestMatchedRule = rule;
						}

					})

					if(bestMatchedRule) {
						// console.log(`Inserting "${this.selector}" after "${bestMatchedRule.parsedSelectors.map(selector => selector.content).join(' ')}" based on selector similarity.`);
						insertPosition = bestMatchedRule.parsedRule.end + 1;
					}

				}

				if(insertPosition > 0) {
					// don't insert any breaks when at the top of the file
					const existingLineBreakCount = countLineBreaksInWhiteSpace(yText.toString(), insertPosition - 1, true);
					// we want at least 2 line breaks between each block
					newLinePrefix = '\n'.repeat(Math.max(2 - existingLineBreakCount, 0));
				}

				// The entire rule does not exist yet. Insert it
				yText.insert(insertPosition, `${newLinePrefix}${this.selector} {\n\t${this.propertyName}: ${newValue};\n}\n`);


			} else if(!this.parsedDeclaration) {
				// CSS contains a rule for the selector, but the rule does not have this property set yet. Insert the property into
				// the pre-existing rule

				if(this.errors.length > 0){
					// an error was found inside this block. Add the property to the start of the block
					// in an attempt to insert the new value before the location of the error
					console.log(`A parser error occurred in the following block: \n\n ${this.parsedRule.content}`, this.errors);
					
					if(this.parsedBlock.start ?? -1 !== -1) {
						yText.insert(
							this.parsedBlock.start + 1, 
							`\n\t${this.propertyName}: ${newValue};`
						);
					} else {
						console.log('Unable to inject propwatcher value', this);
					}

				} else {

					// block is valid, just add the new property at the end of the block so it trumps the specificity
					// of anything that could override this property in the same block.
					const str = yText.toString();

					let indexToInsert;
					let prefix = '\n\t';
					let postfix = options.insertAtStart ? '\n\t' : '\n';

					if(options.insertAtStart) {

						indexToInsert = this.parsedBlock.start + 1;

						const nextDeclaration = findEmptySpaceTillNextDeclaration(str, indexToInsert - 1, true, true);

						// remove all whitespace in between start of block and the first declaration
						yText.delete(indexToInsert, nextDeclaration - indexToInsert);

					} else {
						
						const blockEnd = this.parsedBlock.end - 1;
						
						indexToInsert = findEmptySpaceTillPreviousDeclaration(str, blockEnd, true, true);

						// remove all whitespace in between end of block and the last declaration
						yText.delete(indexToInsert, blockEnd - indexToInsert);

					}

					let newDeclaration = `${prefix}${this.propertyName}: ${newValue};${postfix}`;

					yText.insert(indexToInsert, newDeclaration);
				};

			} else {

				// the rule and declaration both exist. Overwrite the old property value.
				const start = this.parsedValue.start ?? -1;
				const end = this.parsedValue.end ?? -1;

				if (start !== -1 && end !== -1) {

					// we know the declaration location exactly. Just run a simple find & replace
					yText.delete(start, end - start);
					yText.insert(start, newValue);

				} else {

					// the property is there, but the value is missing. Eg: color: ;
					// First check if a trailing semicolon is present, then insert the new value
					const hasTrailingSemicolon = declarationHasTrailingSemicolon(
						yText.toString(), 
						this.parsedDeclaration.end
					);

					yText.insert(this.parsedDeclaration.end, ` ${newValue}${hasTrailingSemicolon ? '' : ';'}`);

				}

			}

			// update the value only after we've updated the CSS
			_setValue.call(this, newValue);

		});

	}

	inheritFrom(newPropWatchersToInheritFrom) {

		if(
			newPropWatchersToInheritFrom !== null
			&& newPropWatchersToInheritFrom !== undefined
			&& newPropWatchersToInheritFrom.constructor.name !== "Array"
		) {
			// ensure we're always dealing with an array, even if a single
			// watcher was passed
			newPropWatchersToInheritFrom = [newPropWatchersToInheritFrom];
		}

		// remove old listener
		if(this.propWatchersToInheritFrom) {
			this.propWatchersToInheritFrom.forEach(watcher => {
				watcher.off('change', this.onInheritedPropWatcherChange)
			});
		}

		this.propWatchersToInheritFrom = newPropWatchersToInheritFrom;

		// set default inherited state. If required we'll set this back to true
		// below.
 		this.propWatcherUsedForInheritance = this.getPropWatcherToInheritFrom(this.value);

		// we're just unsetting the old inherited propWatchers
		if(!newPropWatchersToInheritFrom) {
			return;
 		}

 		this.propWatchersToInheritFrom.forEach(watcher => {
			watcher.on('change', this.onInheritedPropWatcherChange)
		});

 		// and run a manual call so we immediately start comparing
 		if(this.propWatcherUsedForInheritance) {
	 		this.onInheritedPropWatcherChange(
	 			this.propWatcherUsedForInheritance.value,
	 			null,
	 			this.propWatcherUsedForInheritance
	 		);
	 	}

	};

	onInheritedPropWatcherChange = (newValue, oldValue, watcher) => {

		// make sure we are only listening to callbacks from the
		// watcher we're actively inheriting from
		if(
			this.propWatcherUsedForInheritance 
			&& this.propWatcherUsedForInheritance === watcher
		) {

			// sync this propWatcher with it's inherited propWatcher
			_setValue.call(this, newValue);

		}

	}

	destroy = () => {

		this.emit('destroy', [this]);

		// remove event listeners
		this._observers.clear();

		// clean up inheritance listeners
		this.inheritFrom(null);

	}

	removeCSSDeclaration = (options = {}) => {

		if(this.parsedDeclaration){
			this.parser.mutateSharedType(yText => {
				
				const cssString = yText.toString();

				const start = findEmptySpaceTillPreviousDeclaration(cssString, this.parsedDeclaration.start);
				const end = findEmptySpaceTillNextDeclaration(cssString, this.parsedDeclaration.end);

				yText.delete(start, end - start);

			});

			if(
				// option to automatically remove the entire rule after deleting it's last property
				options.deleteEmptyRule
				&& /{\s*}/g.test(this.parsedBlock.content)
			) {

				this.parser.deleteCSSRange(this.parsedRule.start, this.parsedRule.end, {
					includeLeadingLineBreaks: false,
					includeTrailingLineBreaks: true
				});

			}

		}

	}

};