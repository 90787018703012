import axios from 'axios';
import { store } from "../index"
import { actionTypes } from "../actions";

// add OAuth token support to axios
axios.interceptors.request.use( async (config) => {

	if(config.use_auth === true) {

		const state = store.getState();

		if(
			config.headers.Authorization === undefined && // if no Auth was already set
			state.auth.data && // and we have auth data
			state.auth.data.access_token // and a token
		) {

			config.headers['Authorization'] = `Bearer ${state.auth.data.access_token}`;

		}

	}

	return config;

});

// Handle expired token
axios.interceptors.response.use(undefined, error => {

	if(
		error?.config?.use_auth === true 
		&& error?.response?.status === 403
	) {
		// unauthorized request. Assume login credentials are not valid (anymore);

		console.log('Unauthorized request. Logout...');

		store.dispatch({
			type: actionTypes.AUTHENTICATE_USER_REJECTED,
			payload: {}
		});

	}

	return Promise.reject(error);

});