import { YWebSocketProvider } from '@cargo/y-websocket-provider'
import * as Y from 'yjs'
import { store } from "../../index";
import { actions } from "../../actions";
import _ from 'lodash';
import { CRDT_SERVER_ORIGIN, HOMEPAGE_ORIGIN, WEB_HOSTNAME } from "@cargo/common";
// import * as Sentry from "@sentry/browser";
import * as logging from 'lib0/logging';

export * as Y from 'yjs'
export const ydoc = new Y.Doc();
export let wsProvider;

import IdleObserver from './idleObserver';

// Monkeypatch YXmlElement to properly handle void tags 
;(function(){

	const voidElements = ["area", "base", "br", "col", "command", "embed", "hr", "img", "input", "keygen", "link", "meta", "param", "source", "track", "wbr"];
	
	// https://github.com/lodash/lodash/blob/master/escape.js
	const htmlEscapes = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		"'": '&#39;'
	}

	const reUnescapedHtml = /[&<>"']/g
	const reHasUnescapedHtml = RegExp(reUnescapedHtml.source)

	Y.XmlFragment.prototype.toString = function(escapeHTML = true) {

		let result;

		this.doc.transact(() => {
			result = this.toArray().map(xml => xml.toString(escapeHTML)).join('');
		})

		return result;
	}

	Y.XmlElement.prototype.toString = function(escapeHTML = true){

		const attrs = this.getAttributes()
		const stringBuilder = []
		const keys = []
		for (const key in attrs) {
			keys.push(key)
		}
		keys.sort()
		const keysLen = keys.length
		for (let i = 0; i < keysLen; i++) {
			const key = keys[i]
			stringBuilder.push(key + '="' + attrs[key] + '"')
		}
		const nodeName = this.nodeName.toLocaleLowerCase()
		const attrsString = stringBuilder.length > 0 ? ' ' + stringBuilder.join(' ') : '';

		if(voidElements.includes(nodeName)) {
			// void elements don't have content. Return a self closing tag
			return `<${nodeName}${attrsString} />`;
		}

		if(this.nodeName === "SCRIPT") {
			// disable HTML escaping inside scripts
			escapeHTML = false;
		}

		return `<${nodeName}${attrsString}>${
			this.toArray().map(xml => xml.toString(escapeHTML)).join('')
		}</${nodeName}>`

	}

	Y.XmlText.prototype.toStringDefaultExport = Y.XmlText.prototype.toString;
	Y.XmlText.prototype.toString = function(escapeHTML = false){

		const result = this.toStringDefaultExport();

		if(this.getAttribute('nodeType') === Node.COMMENT_NODE) {
			return `<!-- ${result} -->`;
		}

		return escapeHTML ? result.replace(reUnescapedHtml, (chr) => htmlEscapes[chr]) : result;

	}

	Y.XmlText.prototype.originalToDOM = Y.XmlText.prototype.toDOM;
	Y.XmlText.prototype.toDOM = function(_document = document){

		if(this.getAttribute('nodeType') === Node.COMMENT_NODE) {
			return _document.createComment(this.toStringDefaultExport())
		}

		return this.originalToDOM();

	}

	const attributeTester = document.createElement('div');

	Y.XmlElement.prototype.originalSetAttribute = Y.XmlElement.prototype.setAttribute;
	Y.XmlElement.prototype.setAttribute = function(attributeName, attributeValue){

		try {
			attributeTester.setAttribute(attributeName, attributeValue);
		} catch(e) {
			console.error(e);
			console.log(`${attributeName} and/or ${attributeValue} are not valid and will not be set on the this XmlElement.`);
			return;
		}

		return this.originalSetAttribute(attributeName, attributeValue);

	}

})();

export const setupY = (options = {}) => {

	let id;
	let access_token;
	let site_id;
	let pongReceived = false;
	let pingPongInterval = 3000;
	let pingPongIntervalId;
	let offlineTimeoutId;

	const idleObserver = new IdleObserver({
		idleTimeout: 60 * 60000, // 60 minutes
		idleTimeoutWhenHidden: 15 * 60000, // 15 minutes
		onStateChange: (state) => {

			if(state === IdleObserver.ACTIVE) {

				store?.dispatch(actions.updateAdminState({
					idling: false
				}));

				wsProvider.connect();

			} else {

				store?.dispatch(actions.updateAdminState({
					idling: true
				}));

				wsProvider.disconnect();

			}

		}
	});

	if(options.quickConnect === true) {

		try {
			
			let host;

			// grab all required data to connect from localstorage
			({ id, access_token } = JSON.parse(localStorage.getItem('c3-auth')));
			({ id: site_id, host } = JSON.parse(localStorage.getItem('c3-site')));

			// check if site id really belongs to this host. This will 
			// prevent us from using a site id that doesn't belong to this host 
			// (like you switched sites for a domain)
			if(host !== window.location.host) {
				return;
			}

		} catch(e) {}

		if(!id || !site_id || !access_token) {
			// Unable to quick connect because we're missing data. Don't do anything
			return;
		}

	}

	return new Promise((resolve, reject) => {

		if(store) {
			
			const state = store.getState();

			// not authed, bail
			if(state.auth.authenticated !== true) {
				return reject('no auth');
			}

			// set all required info to connect
			id = state.auth.data.id;
			access_token = state.auth.data.access_token;
			site_id = state.site.id
			
		}

		let firstSyncOccurred = false;

		window.wsProvider = wsProvider = new YWebSocketProvider({
			url: CRDT_SERVER_ORIGIN,
			name: String(site_id),
			document: ydoc,
			token: `${BUILD_NUMBER};${id},${access_token}`,
			// Time between sending updates to the CRDT server
			updateSyncInterval: 200,
			// do not send broadcast messages between tabs. Every
			// tab connects to the server individually
			broadcast: false,
			// spend max 30 seconds trying to connect
			timeout: 30000,
			// 1 second between reconnection attempts.
			delay: 1000,
			// do not increase reconnection interval
			factor: 1,
			// do not vary reconnection interval
			jitter: false,
			// disconnect if 20 seconds without receiving messages
			// this includes awareness updates which arrive at least once per 15 seconds
			messageReconnectTimeout: 20000,
			onSaveAs: ({ details }) => {
				if(details.site_url) {
					window.location.host = details.site_url + '.' + WEB_HOSTNAME;
				}
			},
			onPong: () => {
				pongReceived = true;
			},
			onOpen: () => {
				console.log('[YWebSocketProvider] Opened socket');
			},
			onSynced: ({ state }) => {

				// if we're not synced, stop polling. If we're synced, start it up again
				clearInterval(pingPongIntervalId);

				if(state === true) {

					console.log('[YWebSocketProvider] Document synced');

					pingPongIntervalId = setInterval(() => {

						// we haven't gotten a pong since our last ping. Server
						// is unresponsive...
						if(!pongReceived) {

							if(wsProvider.status === 'connected') {

								console.log('[YWebSocketProvider] Lost connectivity with socket server');

								// kill socket
								wsProvider.closeSocket(4100);

								// Sentry.captureMessage("Heartbeat missed");

							}

							return;
						}

						clearTimeout(offlineTimeoutId);

						// set false, this will be set to true when we receive
						// a pong from the server
						pongReceived = false;

						// send the ping
						wsProvider.sendPing();

					}, pingPongInterval);

					idleObserver.start();

					// send first ping
					wsProvider.sendPing();

				} else {
					console.log('[YWebSocketProvider] Document lost sync');
				}


				if(firstSyncOccurred) {
					return;
				}

				firstSyncOccurred = true;
				wsProvider.firstSyncOccurred = true;

				if (! state) {
					return reject('could not sync ydoc')
				}

				let missedSyncs = 0;

				// check sync status every second
				setInterval(() => {

					if(idleObserver.state === IdleObserver.IDLE) {
						// don't run while idle
						return;
					}

					const state = store?.getState();

					if(wsProvider.synced) {

						// Unlock the admin if it was previously locked
						if(state?.adminState?.CRDTSynced !== wsProvider.synced) {

							// unlock admin
							store?.dispatch(actions.updateAdminState({
								CRDTSynced: wsProvider.synced
							}));

							// Sentry.captureMessage("Regained CRDT sync");

						}

						// reset missed sync count
						missedSyncs = 0;

					} else {

						missedSyncs++;

						// lock admin after 5 missed sync intervals (5 seconds)
						if(missedSyncs >= 5 && state?.adminState?.CRDTSynced !== wsProvider.synced) {

							// lock admin
							store?.dispatch(actions.updateAdminState({
								CRDTSynced: wsProvider.synced
							}))
							
							// Sentry.captureMessage("Lost CRDT sync");

						}

					}

				}, 1000);

				resolve(wsProvider);

			},
			onAuthenticationFailed: (event) => {
				return reject(event.reason)
			},
			onClose: ({ event }) => {

				console.log(`[YWebSocketProvider] Closed socket with code ${event.code}`);

				switch (event.code) {
				case 4401:
				case 4403:
					// Connection closed due to auth issues. Wipe auth and present login screen:
					store?.dispatch({
						type: 'AUTHENTICATE_USER_REJECTED',
						payload: {}
					});
					break;
				case 4410:
					displayMessageAndRedirect('Site deleted, redirecting...', HOMEPAGE_ORIGIN);
					break;
				case 4423:
					displayMessageAndRedirect('Access revoked, redirecting...', HOMEPAGE_ORIGIN);
					break;
				case 4426:

					// the CRDT server rejected the connection as the admin sent a build version number that
					// is out of date. Force a reload in an attempt to load the latest admin build
					try {

						// use session storage to prevent infinite loops
						const ranOutdatedBuildReload = sessionStorage.getItem('ranOutdatedBuildReload');

						if(ranOutdatedBuildReload) {

							console.log('[YWebSocketProvider] Unable to automatically recover from 4426');

							// Run callback
							options.onOutdatedBuild?.();

							// reset session storage
							sessionStorage.removeItem('ranOutdatedBuildReload');

						} else {

							// set a session storage item to prevent reload loops
							sessionStorage.setItem('ranOutdatedBuildReload', true);

							// reload immediately
							window.location.reload();

						}

					} catch(e) {

						// Run callback immediately as we can't safely trigger a reload
						options.onOutdatedBuild?.();

					}

					break;
				case 1009:
					// @TODO: Seek copy approval for 'You pasted something way too big'
					displayMessageAndRedirect('Update too large, reloading...');
					break;
				default:
					break;
				}
			}
		});

	});

}

const displayMessageAndRedirect = (message = '', location = null) => {
	// Prevent reconnection attempts during setTimeout delay
	window.wsProvider.shouldConnect = false;

	document.dispatchEvent(
		new CustomEvent('open-remote-message', {
			detail: { message }
		})
	);

	setTimeout(() => {
		if (typeof location === 'string') {
			window.location.href = location;
		} else {
			window.location.reload();
		}
	}, 2000);
};

window.ydoc = ydoc;
window.Y = Y;
