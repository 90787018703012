const whiteSpace = /^\s*$/;
const lineBreak = /\r\n?|\n|\u2028|\u2029/

export const declarationHasTrailingSemicolon = (string, index) => {

	while(index <= string.length) {

		// found a non-whitespace character
		if(!whiteSpace.test(string.charAt(index))) {
			return string.charAt(index) === ';';
		}

		index++;
	}

	return false;

}

export const findEmptySpaceTillNextDeclaration = (string, index, includeLastBreak = true, ignoreBreaks = false) => {

	if(index >= string.length) {
		return string.length
	}

	// move one char forward
	index++;

	// if next char is not whitespace, just return the original index
	if(!whiteSpace.test(string.charAt(index))) {
		return index - 1;
	}
	
	// look forward
	while(index <= string.length) {

		const char = string.charAt(index);

		// found a non-whitespace character or line break
		if(
			// found something that isn't whitespace or a semicolon
			(!whiteSpace.test(char) && char !== ';')
			// or found a line break
			|| (!ignoreBreaks && lineBreak.test(char))
		) {

			if(!includeLastBreak && lineBreak.test(char)) {
				return index - 1;
			} else {
				return index;
			}

		}

		index++;
	}

	return Math.min(index, string.length);

}

export const findEmptySpaceTillPreviousDeclaration = (string, index, includeLastBreak = true, ignoreBreaks = false) => {

	if(index <= 0) {
		return 0;
	}

	// move one char back
	index--;
	
	// if next char is not whitespace, just return the original index
	if(!whiteSpace.test(string.charAt(index))) {
		return index + 1;
	}

	// look backwards
	while(index >= 0) {

		const char = string.charAt(index);
		
		// found a non-whitespace character or line break
		if(
			// found something that isn't whitespace
			!whiteSpace.test(char)
			// or found a line break
			|| (!ignoreBreaks && lineBreak.test(char))
		) {

			if(includeLastBreak && lineBreak.test(char)) {
				return index;
			} else {
				return index + 1;
			}

		}

		index--;
	}

	return Math.max(index, 0);

}

export const countLineBreaksInWhiteSpace = (string, index, reverse = false) => {

	let breakCount = 0;
	
	// look forward
	while(reverse ? index >= 0 : index <= string.length) {

		const char = string.charAt(index);

		if(!whiteSpace.test(char)) {
			return breakCount;
		}

		if(lineBreak.test(char)) {
			breakCount++;
		}

		reverse ? index-- : index++;
	}

	return breakCount;

}

// https://github.com/aceakash/string-similarity/blob/master/src/index.js
export const compareStringSimilarity = (first, second) => {
	first = first.toLowerCase().replace(/\s+/g, '')
	second = second.toLowerCase().replace(/\s+/g, '')

	if (first === second) return 1; // identical or empty
	if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

	let firstBigrams = new Map();
	for (let i = 0; i < first.length - 1; i++) {
		const bigram = first.substring(i, i + 2);
		const count = firstBigrams.has(bigram)
			? firstBigrams.get(bigram) + 1
			: 1;

		firstBigrams.set(bigram, count);
	};

	let intersectionSize = 0;
	for (let i = 0; i < second.length - 1; i++) {
		const bigram = second.substring(i, i + 2);
		const count = firstBigrams.has(bigram)
			? firstBigrams.get(bigram)
			: 0;

		if (count > 0) {
			firstBigrams.set(bigram, count - 1);
			intersectionSize++;
		}
	}

	return (2.0 * intersectionSize) / (first.length + second.length - 2);
}