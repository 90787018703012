import { actionTypes } from "../actions";
import { helpers } from '@cargo/common';
import _ from "lodash";
import { PublishState } from '../globals';

let localStorageContents = {};

try {
	localStorageContents = JSON.parse(localStorage.getItem('adminState.localstorage'))
} catch(e) {
	if (! helpers.isServer || ! helpers.isLocalEnv) {
		console.log('Unable to retrieve admin state from localStorage');
	}
}

// Method to clear localstorage from the console
window.clearAdminLocalStorage = () => {
	localStorage.clear()
	window.location.reload()
}

const defaultAdminState = {
	viewport: 'desktop',
	mobileRatio: .4615,
	hasAdminList: false,
	hasSubscriptions: false,
	hasCollaborators: false,
	matchedRoute: null,
	initialized: false,
	localStorage: localStorageContents || {},
	publishing: false,
	publishingAnimationActive: false,
	pauseGlobalEventExecution: false,
	adminLockedSpinner: false,
	previewMessage: null,
	CRDTSynced: false,
	darkMode: false,
	crdt: {
		publishState: PublishState.Published
	},
	session: {
		uiWindowPosition: {}
	},
	uiWindowLayer: {},
	lastClickCoordinates: {},
	uploadingMedia: false,
	promoCodeTransition: null,
	productFetchRequired: true,
}

export default function(state = defaultAdminState, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {
		
		case actionTypes.UPDATE_ADMIN_STATE: 

			var newState = _.assign({}, defaultAdminState, state, action.payload);

			if(action.payload.session){

				newState = {
					...newState,
					session: {
						...state.session,
						...action.payload.session 
					}
				}

			}

			// new localStorage stuff came in
			if(action.payload.localStorage) {

				newState = {
					...newState,
					localStorage: {
						...state.localStorage,
						...action.payload.localStorage 
					}
				}

				// save to localStorage
				localStorage.setItem('adminState.localstorage', JSON.stringify(newState.localStorage))

			}

			return newState;

		case actionTypes.STORE_UIWINDOW_POS:

			var newState = _.assign({}, defaultAdminState, state, action.payload);

			if( action.payload ){
				// create new instance of the entire session state object
				newState = {
					...state
				}

				let currentSessionState = {
					...newState.session 
				}

				// Merge data in window position object.
				let newUiWindowPositionState = {
					uiWindowPosition: {
						...newState.session.uiWindowPosition,
						...action.payload
					}
				}
				// Merge data in session state object
				const newSessionState = {
					...currentSessionState,
					...newUiWindowPositionState
				}
				// Assign new session state to greater object in store.
				newState = {
					...newState,
					session: newSessionState
				}
			}		

			// new localStorage stuff came in
			if(action.payload.localStorage) {
								
				// save to localStorage
				localStorage.setItem('adminState.localstorage', JSON.stringify(newState.localStorage))

			}

			return newState;

		case actionTypes.FETCH_ADMIN_LIST_FULFILLED: 

			return {
				...state,
				hasAdminList: true
			}

		case actionTypes.FETCH_SUBSCRIPTIONS_FULFILLED:

			return {
				...state,
				hasSubscriptions: true
			}
		
		case actionTypes.FETCH_COLLAB_FULFILLED:

			return {
				...state,
				hasCollaborators: true
			}

	}

	return state;

}