import { globalUndoManager } from "../lib/undo-redo";
import _ from 'lodash';

import adminState from "./admin-state";
import uiWindows from "./ui-windows";
import fontCollection from "./font-collection";
import domains from "./domains";
import subscriptions from './subscriptions';
import user from "./user";
import collaborators from "./collaborators";

import { sharedReducer } from "../lib/multi-user/redux";

export const ignoredSiteModelFields = [
	'id',
	'direct_link',
	'display_url',
	'domain',
	'domain_active',
	'site_url',
	'css_url',
	'rss_url',
	'access_level',
	'tags',
	'favicon_url',
	'recent_date',
	'version',
	'social_width',
	'social_height',
	'site_password_enabled',
	'site_password',
	'is_template',
	'editors',
	'folders',
	'is_private',
	'is_deleted',
	'is_upgraded',
	'shop_id',
	'has_annex_upgrade',
	'total_file_size',
	'domain_purchased',
	'domain_pending',
	'screenshot',
	'contact_form_recipient',
	'email_all_admins',
	'email'
];

export default (history, frontendReducers, combineReducers) => {

	const defaultFrontendReducers = {...frontendReducers.default}

	defaultFrontendReducers.siteDesign = sharedReducer(defaultFrontendReducers.siteDesign, {
		path: `siteDesign`,
		ignoredPaths: [
			// ignore values being saved using pub/sub
			'contact_form.theme',
			'cart.theme'
		]
	});

	// make CSS shareable
	defaultFrontendReducers.css = sharedReducer(defaultFrontendReducers.css, {
		path: `css`
	});
	
	// make media library shareable
	defaultFrontendReducers.media = sharedReducer(defaultFrontendReducers.media, {
		path: `media`
	});

	// make Site model shareable
	defaultFrontendReducers.site = sharedReducer(defaultFrontendReducers.site, {
		path: `site`,
		ignoredPaths: ignoredSiteModelFields
	})

	defaultFrontendReducers.pages = combineReducers({
		...frontendReducers.rawReducers.pages,
		byId: sharedReducer(frontendReducers.rawReducers.pages.byId, {
			path: 'pages.byId'
		})
	});

	defaultFrontendReducers.sets = combineReducers({
		...frontendReducers.rawReducers.sets,
		byId: sharedReducer(frontendReducers.rawReducers.sets.byId, {
			path: 'sets.byId',
			ignoredPaths: [
				// ignore everything in the root set except 'page_count'
				'root.content',
				'root.display',
				'root.id',
				'root.is_deleted',
				'root.backdrops',
				'root.local_css',
				'root.media',
				'root.page_design_options',
				'root.page_type',
				'root.password_enabled',
				'root.pin',
				'root.pin_options',
				'root.purl',
				'root.tags',
				'root.tags_with_links',
				'root.thumb_is_visible',
				'root.thumb_media_id',
				'root.thumb_meta',
				'root.title'
			]
		})
	});

	defaultFrontendReducers.structure = sharedReducer(defaultFrontendReducers.structure, {
		path: 'structure'
	});

	const res = combineReducers({
		adminState: sharedReducer(adminState, {
			path: 'adminState'
		}),
		uiWindows: uiWindows,
		fontCollection: fontCollection,
		domains: domains,
		subscriptions: subscriptions,
		collaborators: collaborators,
		user: user,
		...defaultFrontendReducers
	});

	return res;

}