import { Y } from "../multi-user";
import { sharedReducerMap} from "../multi-user/redux";

let observer = null;

/**
 * Method to listen to CSS updates to the CRDT, and correctly set the yText instance in a parser
 */
export const setupSharedCSSListener = (parser, options) => {

	// If the parsers yText isn't connected to the ydoc, we need to listen for when it's added to the shared reducer
	const sharedReducer = options.local ? sharedReducerMap.get('pages.byId')?.type : sharedReducerMap.get('css')?.type;
	const pathToCSS = options.local ? 'local_css' : 'stylesheet';

	//observer = CRDTChangeListener(parser, options.local, options.pid ?? null, sharedReducer);
	sharedReducer.observeDeep(events => {

		events.forEach(event => {

			if(
				event.target instanceof Y.Map
				&& (!options.local
					|| (options.local && event.path[0] == options.pid) 
				)
			) {

				if(event.changes.keys.has(pathToCSS)) {
					
					const newValue = event.target.get(pathToCSS);
					const oldValue = event.changes.keys.get(pathToCSS).oldValue;

					if(oldValue instanceof Y.Text === false && newValue instanceof Y.Text === true){ 
					
						// console.log('CSS set to Y.Text');
						// the CSS value transitioned to a Y.Text
						parser.updateType(newValue);

					} else if (oldValue instanceof Y.Text === true && newValue instanceof Y.Text === false) {

						// console.log('CSS no longer a Y.Text');
						// the CSS value was Y.Text but is not anymore
						parser.updateType(new Y.Text());

					}

				}

			}
		});

	});

};
