import { YTransactionTypes } from "../../globals";
import { Y, ydoc } from "../multi-user";
import _ from "lodash";

export const observePageContentForMediaChanges = () => {

	ydoc.get('draft-store').get('pages').get('byId').observeDeep((events, transaction) => {

		if(!transaction.local) {
			return;
		}

		const pagesWithMediaItemChanges = [];

		// check to see if a media item was added or removed from page content
		events.forEach(event => {

			let foundMediaItemChanges = false;

			if(event.target instanceof Y.Map) {

				// look for content changes
				if(event.keys.has('content')) {
					foundMediaItemChanges = true;
				}

				// look for thumbnail changes
				if(event.keys.has('thumb_media_id')) {
					foundMediaItemChanges = true;
				}

				// or backdrop changes
				if(event.path[1] === 'backdrops') {
					foundMediaItemChanges = true;
				}

			} else if(event.target instanceof Y.XmlFragment) {

				// look for hash changes
				if(event.attributesChanged.has('hash')) {
					foundMediaItemChanges = true;
				}

				// look for poster changes
				if(event.attributesChanged.has('poster')) {
					foundMediaItemChanges = true;
				}

				// find added media items
				if(!foundMediaItemChanges) {
					for(const item of event.changes.added) {
						if(item.content.type && item.content.type.nodeName === "MEDIA-ITEM") {
							foundMediaItemChanges = true;
							break;
						} else if(item.content.type && item.content.type instanceof Y.XmlFragment) {
							// look for media items inside the added content
							for (const mediaItem of item.content.type.createTreeWalker(type => type.nodeName === "MEDIA-ITEM")) {
								foundMediaItemChanges = true;
								break;
							}
						}
					}
				}
				
				// find removed media items
				if(!foundMediaItemChanges) {
					for(const item of event.changes.deleted) {
						if(item.content.type && item.content.type.nodeName === "MEDIA-ITEM") {
							foundMediaItemChanges = true;
							break;
						} else if(item.content.type && item.content.type instanceof Y.XmlFragment) {
							// look for media items inside the added content
							for (const mediaItem of item.content.type.createTreeWalker(type => type.nodeName === "MEDIA-ITEM")) {
								foundMediaItemChanges = true;
								break;
							}
						}
					}
				}

			}

			if(foundMediaItemChanges) {
				let parent = event.target;
				// find parent page
				while(parent) {
					if(parent.get('page_type') === "page" && parent.has('content')) {
						// got the page, add it to the list
						if(!pagesWithMediaItemChanges.includes(parent)) {
							pagesWithMediaItemChanges.push(parent);
						}
						break
					}
					parent = parent.parent;
				}
			}

		});

		ydoc.transact(() => {

			pagesWithMediaItemChanges.forEach(page => {
				const inUseMedia = getInUsePageMedia(page);

				// loop over all media in the page, and update `in_use` if needed
				page.get('media').forEach?.(mediaType => {
					if(mediaType instanceof Y.Map) {
						const newInUseState = inUseMedia.includes(mediaType.get('hash'));
						if(mediaType.get('in_use') !== newInUseState) {
							// console.log('setting in_use to', newInUseState, 'on', mediaType.toJSON())
							mediaType.set('in_use', newInUseState);
						}
					}
				});
			});

		}, YTransactionTypes.NotUndoable);

	});

}

export const getInUsePageMedia = page => {

	const pageIsYJSMap = page instanceof Y.Map;
	const pageContent = pageIsYJSMap ? page.get('content') : page.content;
	const pageJSON = pageIsYJSMap ? page.toJSON() : page;

	let hashes = [];

	// find images used by a backdrop.
	if((pageJSON.backdrops?.activeBackdrop || 'none') !== "none") {

		const activeBackdrop = pageJSON.backdrops.activeBackdrop;
		const backdropSettings = pageJSON.backdrops.backdropSettings?.[activeBackdrop] || {};
		const excludedHashes = backdropSettings.excluded || [];

		switch(activeBackdrop) {

			case 'wallpaper':
			case 'legacy/morphovision':

				if(backdropSettings['cycle-images'] === true || backdropSettings['cycle_images'] === true) {

					// mark every images on the page except the ones marked as excluded
					pageJSON.media.forEach(media => {
						if(!excludedHashes.includes(media.hash)) {
							hashes.push(media.hash);
						}
					});

				} else if(backdropSettings.activeImage || backdropSettings.image) {

					// mark the active image as in use
					if(backdropSettings.activeImage) {
						hashes.push(backdropSettings.activeImage);
					}

					if(backdropSettings.image) {
						hashes.push(backdropSettings.image);
					}

				}

				break;

			default:

				if(backdropSettings.image) {
					// mark the active image as in use
					hashes.push(backdropSettings.image);
				}

				break;

		}

	}

	// find all media in the page content
	if(pageContent instanceof Y.XmlFragment) {

		Array.from(pageContent.createTreeWalker(element => element.nodeName === "MEDIA-ITEM")).forEach(type => {

			if(type.hasAttribute('hash')) {
				hashes.push(type.getAttribute('hash'))
			}

			if(type.hasAttribute('poster')) {
				hashes.push(type.getAttribute('poster'))
			}

		});

	} else if(typeof pageContent === "string") {

		const parser = new DOMParser();
		const newParsedDocument = parser.parseFromString(pageContent, "text/html");

		Array.from(newParsedDocument.querySelectorAll('MEDIA-ITEM')).forEach(el => {

			if(el.hasAttribute('hash')) {
				hashes.push(el.getAttribute('hash'))
			}

			if(el.hasAttribute('poster')) {
				hashes.push(el.getAttribute('poster'))
			}

		});

	}

	// include page thumbnail if set
	const thumbnail = pageJSON.media.find(media => media.id === pageJSON.thumb_media_id);

	if(thumbnail) {
		hashes.push(thumbnail.hash);
	}

	return _.uniq(hashes);

}
