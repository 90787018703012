// https://github.com/mahirshah/css-property-parser/blob/master/src/constants/shorthandIdentToLonghandPropertyMap.json

const shorthandMap = {
    "transition": [
        "transition-timing-function",
        "transition-property",
        "transition-duration",
        "transition-delay"
    ],
    "mask": [
        "mask-clip",
        "mask-origin"
    ],
    "animation": [
        "animation-play-state",
        "animation-fill-mode",
        "animation-iteration-count",
        "animation-direction",
        "animation-timing-function",
        "animation-name",
        "animation-duration",
        "animation-delay"
    ],
    "flex": [
        "flex-grow",
        "flex-shrink",
        "flex-basis"
    ],
    "background": [
        "background-image",
        "background-color",
        "background-origin",
        "background-clip",
        "background-position",
        "background-size",
        "background-repeat",
        "background-attachment"
    ],
    "font": [
        "font-style",
        "font-variant",
        "font-weight",
        "font-stretch",
        "font-size",
        "line-height",
        "font-family"
    ],
    "border-radius": [
        "border-top-left-radius",
        "border-top-right-radius",
        "border-bottom-right-radius",
        "border-bottom-left-radius"
    ],
    "padding": [
        "padding-top",
        "padding-right",
        "padding-bottom",
        "padding-left"
    ],
    "margin": [
        "margin-top",
        "margin-right",
        "margin-bottom",
        "margin-left"
    ],
    "border-width": [
        "border-top-width",
        "border-right-width",
        "border-bottom-width",
        "border-left-width"
    ],
    "border-style": [
        "border-top-style",
        "border-right-style",
        "border-bottom-style",
        "border-left-style"
    ],
    "border-color": [
        "border-top-color",
        "border-right-color",
        "border-bottom-color",
        "border-left-color"
    ],
    "text-emphasis": [
        "text-emphasis-color",
        "text-emphasis-style"
    ],
    "text-decoration": [
        "text-decoration-color",
        "text-decoration-style",
        "text-decoration-line"
    ],
    "outline": [
        "outline-style",
        "outline-width",
        "outline-color"
    ],
    "list-style": [
        "list-style-type",
        "list-style-position",
        "list-style-image"
    ],
    "flex-flow": [
        "flex-wrap",
        "flex-direction"
    ],
    "columns": [
        "column-count",
        "column-width"
    ],
    "column-rule": [
        "column-rule-width",
        "column-rule-style",
        "column-rule-color"
    ],
    "border-top": [
        "border-top-color",
        "border-top-style",
        "border-top-width"
    ],
    "border-right": [
        "border-right-color",
        "border-right-style",
        "border-right-width"
    ],
    "border-left": [
        "border-left-color",
        "border-left-style",
        "border-left-width"
    ],
    "border-bottom": [
        "border-bottom-color",
        "border-bottom-style",
        "border-bottom-width"
    ],
    "border-inline-start": [
        "color",
        "border-style",
        "border-width"
    ],
    "border-inline-end": [
        "color",
        "border-style",
        "border-width"
    ],
    "border-block-start": [
        "color",
        "border-style",
        "border-width"
    ],
    "border-block-end": [
        "color",
        "border-style",
        "border-width"
    ],
    "border": [
        "border-color",
        "border-style",
        "border-width"
    ]
}

export const metadata = {
	byShortHand: shorthandMap, 
	shorthands: Object.keys(shorthandMap),
	longhands: Object.values(shorthandMap).reduce((all, longhandsForShorthand) => all.concat(longhandsForShorthand), [])
}