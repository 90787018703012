import { actionTypes } from "../actions";
import { combineReducers } from 'redux';

import _ from "lodash";


export default function(state = {}, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {
		
		case actionTypes.FETCH_DOMAINS: 

			let newState = action.payload;

			return state;

		case actionTypes.FETCH_DOMAINS_FULFILLED: 

			return {
				list: [ ...action.payload.data ],
				domainsLoaded: true
			}


	}

	return state;

}