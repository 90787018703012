import { Y, ydoc } from '../../';
import _ from 'lodash';

// This function converts an object to a YJS shareable type. We have to 
// deep clone everything because YJS doesn't like data to come in from a foreign document (our iframe).
// Because our Redux state is coming from both the admin and frontend contexts we just clone all objects
// to ensure YJS won't crash.

const convertStateToSharedType = (state, rootType, filter, origin) => {

	// recursively copy and convert all fields in an object into a sharable type
	function convert(object, rootType, path) {

		return _.cloneDeepWith(object, value => {
			
			// We can leave this undefined when we just want the native cloneDeep behavior.
			let coercedValue;

			// when we find an array in the state, convert it to a Y.Array
			if(_.isArray(value)) {
				
				coercedValue = rootType || new Y.Array();

				// copy the contents of the array into the new Y.Array
				for (let index = 0; index < value.length; index++) {
					
					const newPath = [...path, `[${index}]`];

					if(filter && filter(newPath, value[index]) === false) {
						continue;
					}

					coercedValue.push([convert(value[index], undefined, newPath)])
				}

			// when we find an object in the state, convert it to a Y.Map
			} else if(_.isObjectLike(value)) {

				coercedValue = rootType || new Y.Map();

				// copy all fields in the state into the new Y.Map
				for (const field in value) {

					if(!value.hasOwnProperty(field)) {
						continue;
					}
					
					const newPath = [...path, field];

					if(filter && filter(newPath, value[field]) === false) {
						continue;
					}

					coercedValue.set(field, convert(value[field], undefined, newPath));
				}

			}

			return coercedValue;

		});

	}

	let result;

	ydoc.transact(() => {
		// kick off the recursion
		result = convert(state, rootType, []);
	}, origin);

	return result;
	
}

export default convertStateToSharedType;