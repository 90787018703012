import { actionTypes } from "../actions";
import { combineReducers } from 'redux';

import _ from "lodash";


export default function(state = {}, action) {

	if(!action.payload && !action.meta) {
		return state;
	}

	switch(action.type) {
		
		case actionTypes.FETCH_USER: 

			let newState = action.payload;
			return state;

		case actionTypes.FETCH_USER_FULFILLED: 

			return {
				...action.payload.data,
			}

		case actionTypes.UPDATE_USER_PENDING: 

			if( action.meta ) {
				
				const logState = { ...state, ...action.meta }

				return {
					...state,
					...action.meta
				}
			}

		break;

		case actionTypes.UPDATE_USER_FULFILLED: 

			if( action.payload ) {
				const logState = { ...state, ...action.payload.data }

				return {
					...state,
					...action.payload.data
				}
			}

		break;


		case actionTypes.UPDATE_USER_META_PENDING: 
			return {
				...state,
				meta: action.meta
			}

		case actionTypes.UPDATE_USER_META_FULFILLED: 

			return {
				...state,
				meta: action.payload.data.meta
			}


	}

	return state;

}