import getGlobalCSSParser from "./get-global-parser";
import { store } from '../../index';
import _ from 'lodash';
import { getCRDTItem, applyChangesToYType } from "../multi-user/redux";
import { Y, ydoc } from "../multi-user";
import { YTransactionTypes } from "../../globals";
import { archivedFontReplacementsData } from "../../defaults/archived-font-replacements";
import * as Sentry from "@sentry/browser";

export const subscriptions = new Map();

subscriptions.set("text-style-selectors", []);

const updateTextStyleSubscriptions = evt => {

    const { selector, type, value } = evt;
    const subscribedSelectors = subscriptions.get("text-style-selectors");

    if(type === "added" && value){

        const updatedSelectors = [...subscribedSelectors, {
            name: value.trim().replace(/^["']/, '').replace(/["']$/, ''), // converts - /"my style"/ to mystyle
            tag: selector.content,
            className: selector.content.replace(/^\./, '')
        }];

        subscriptions.set("text-style-selectors", updatedSelectors);

    } else if(type === "updated"){

        const updatedSelectors = [...subscribedSelectors];
        const existingSubscription = updatedSelectors.find(subscribed => subscribed.tag === selector.content);

        if(existingSubscription) {
            existingSubscription.name = value.trim().replace(/^["']/, '').replace(/["']$/, '');

            subscriptions.set("text-style-selectors", updatedSelectors);
        }

    } else if(type === "removed"){
        
        subscriptions.set("text-style-selectors", subscribedSelectors.filter(subscribed => subscribed.tag !== selector.content));

    }
}

const generateFontChangeObj = (variantArray, index, selector) => {
	
	let dataToStore = variantArray[index]

	let dataFamily 	 = _.get(dataToStore, 'cssName'); // font-family: "Helvetica Neue"
	let dataWeight 	 = _.get(dataToStore, 'weight'); // font-weight: "400"
	let dataStyle    = _.get(dataToStore, 'css_font_style');  // font-style: "normal"
	let dataFVS      = _.get(dataToStore, 'writeable_fvs'); // font-variation-settings: 'ital' 0, 'SRFF' 100;
	let dataRawFVS   = _.get(dataToStore, 'css_font_fvs');

	if( dataRawFVS ){
        let fvsString = dataRawFVS;
        let stringArr = fvsString?.split(',')
        let fvsMap = {};
    
        _.each(stringArr, function(FVS, index){
            let property = FVS.trim().split("' ")[0].trim().replace(/\'/g, '');
            let val = parseFloat(FVS.trim().split("' ")[1])
    
            fvsMap[property] = val;
        })

		if( fvsMap['wght'] ){
			dataWeight = fvsMap['wght'];
		}
	}

	if( !dataWeight && dataWeight !== 0 ){
		dataWeight = null;
	}

	let changeObj = {
		'font-family'             : dataFamily,
		'font-weight'             : dataWeight,
		// 'font-style'              : dataStyle, //don't change font style...
		'font-variation-settings' : dataFVS,
	}

	if( dataFamily.includes("Gravity") ){
		changeObj['font-feature-settings'] = '"ss03", "ss05", "ss06", "ss07", "ss09"';
	}

	if(selector){
		changeObj = {
			[selector]: changeObj
		}
	}

	return changeObj;
}

const replaceDepricatedFonts = () => {
    const state = store.getState();

    if (!state.fontCollection.hasFontCollection || _.isEmpty(state.site)) {
        return; // only run when we have a font collection & site model data
    }

    const parser = getGlobalCSSParser();
    const parsedRules = parser.getParsedRules();

    parsedRules.forEach(rule => {
        if (rule.parsedProperties.has('font-family')) {
            let family = rule.parsedProperties.get('font-family')?.content?.replace(/"/g, '');
            let weight = rule.parsedProperties.get('font-weight')?.content?.replace(/"/g, '');
            let style  = rule.parsedProperties.get('font-style')?.content?.replace(/"/g, '');
            let selector = rule.parsedSelectors[0].content;

            if (!family) {
                // log the information we have to tell why there is no family match
                console.log("No font family found for", selector);
                return;
            }

            _.each(archivedFontReplacementsData, (replacement, originalFontName) => {
        
                if (originalFontName === family && replacement.variantMap) {

                    let matchedVariant = _.find(replacement.variantMap, variant => {
                        return variant.weight === weight && variant.style === style;
                    });

                    let replacementFamily = null;
                    let replacementVariant = null;
                    
                    if( matchedVariant ){

                        replacementFamily = state.fontCollection.collection.find(font => font.family === matchedVariant.replace_with );

                        if (!replacementFamily) {
                            console.log("No matching replacement family:", matchedVariant.replace_with );
                            return
                        }

                        replacementVariant = replacementFamily.variants.find(variant => variant.vid === matchedVariant.vid);
                    }
          
                    if (replacementVariant) {
                        let changeObject = generateFontChangeObj([replacementVariant], 0, selector);
                        // console.log("change object", changeObject )
                        console.log(selector, "has deprecated font family", family);
                        console.log("replacing", family, style, weight, "with", changeObject[selector]);
                        console.log("---")

                        const watchers = _.keyBy(parser.getPropWatchers([
                            { selector, property: 'font-family' },
                            { selector, property: 'font-style' },
                            { selector, property: 'font-weight' },
                            { selector, property: 'font-variation-settings' },
                            { selector, property: 'font-feature-settings' }
                        ]), 'propertyName');

                        _.each(changeObject[selector], (newValue, property) => {
                            watchers[property]?.setValue(newValue);
                        });
                    }
                    if (!replacementVariant) {
                        console.log("No matching variant found for", family, "with weight:", weight, "and style:", style, archivedFontReplacementsData);
                    }
                }
            });
        }
    });
}

const detectCustomFontChanges = _.throttle(() => {

    const state = store.getState();

    if(
        !state.fontCollection.hasFontCollection
        || _.isEmpty(state.site)
    ) {
        // only run when we have a font collection & site model data
        return;
    }

    const parser = getGlobalCSSParser();
    const parsedRules = parser.getParsedRules();
    
    let fonts = [];
    const parsedFamilies = [];

    parsedRules.forEach(rule => {

        if(rule.parsedProperties.has('font-family')) {

            let family = rule.parsedProperties.get('font-family').content?.replace(/"/g, '');
            
            if(!family) {
                return;
            } else {
                // keep track of families that have been parsed
                if(!parsedFamilies.includes(family)) {
                    parsedFamilies.push(family);
                }
            }
            
            const fontDefinition = state.fontCollection.collection.find(def => def.family.toLowerCase().trim() === family.toLowerCase().trim());

            if(!fontDefinition) {
                return;
            }

            if(!fonts.find(font => font.family === family)) {
                fonts.push({
                    family,
                    provider: fontDefinition.provider
                })
            }

        }

    });


    // Define an array of font family names to match
    const archivedFontFamilies = [
        'Adelphe',
        'Adobe Arno Pro',
        'Adobe Poetica',
        'Aften',
        'Alegreya',
        'Archivo Black',
        'Archivo Narrow',
        'Authentic Sans',
        'Authentic Sans Condensed',
        'Avara',
        'Averia Serif Libre',
        'Bagnard',
        'Basteleur',
        'Benton',
        'Big Caslon FB',
        'Bureau',
        'Cormorant',
        'Crimson Text',
        'DINosaur',
        'DINosaur',
        'DM Mono',
        'DM Sans',
        'Dolly Pro',
        'Fondamento',
        'Fort',
        'Fraunces',
        'Freight Big',
        'Freight Text',
        'Garage Gothic',
        'Golos Text',
        'Guyot Text',
        'Halibut Serif Consensed',
        'Halibut Serif Expanded',
        'Halibut Serif Regular',
        'Interstate',
        'JetBrains Mono',
        'Junicode',
        'Karla',
        'Lab Mono',
        'Le Murmure',
        'Libertinus Mono',
        'MVB Solitaire',
        'Marat',
        'Marat Sans',
        'Messapia',
        'Miller Text',
        'Mister Pixel',
        'Neuton',
        'Nitti',
        'Nitti Grotesk',
        'Nunito',
        'Optician',
        'Ortica',
        'Oswald',
        'Paysage',
        'Piazolla',
        'Planet',
        'Quiosco',
        'Quiosco',
        'Rubik',
        'Sabon',
        'Salvo Sans Condensed',
        'Salvo Sans Condensed',
        'Selavy',
        'Sometype Mono',
        'Space Grotesk',
        'Space Mono',
        'Spectral',
        'Sprat',
        'Sprat Condensed',
        'Sprat Expanded',
        'Stanley',
        'Syne',
        'Titling Gothic',
        'Whitman',
        'Williams Caslon Text',
        'Work Sans',
        'Wremena',
        'Young Serif'
    ];

    const fontsToRecoverOnce = [
        "Adobe Arno Pro",
        "Adobe Poetica",
        "Authentic Sans",
        "Authentic Sans Condensed",
        "Big Caslon FB",
        "Dolly Pro",
        "Freight Big",
        "Freight Text",
        "Garage Gothic",
        "Guyot Text",
        "Interstate",
        "MVB Solitaire",
        "Marat",
        "Marat Sans",
        "Miller Text",
        "Nitti",
        "Nitti Grotesk",
        "Sabon",
        "Selavy",
        "Stanley",
        "Whitman",
        "Williams Caslon Text",
        "Wremena"
    ]

    // temporary fix for fonts that have been removed but we don't want to remove 
    // from the site's store so that they still get loaded on the frontend
    if (state.site.fonts) {
        state.site.fonts.forEach(font => {
            if (archivedFontFamilies.some(archivedFont => font.family.match(archivedFont))) {
                if (parsedFamilies?.indexOf(font.family) !== -1) {
                    console.log('keep loading font removed from collection:', font.family)
                    fonts.push({
                        family: font.family,
                        provider: font.provider
                    });
                }
            }
        });
    }

    const { CRDTItem: sharedAdminState } = getCRDTItem({
        reducer: 'adminState',
        item: 'crdt'
    });

    if(sharedAdminState.get('ranGrandfatheredFontCheck') !== true) {

        const recoverableFonts = _.intersection(parsedFamilies, fontsToRecoverOnce);

        // do a one-time restore for certain fonts that we failed to grandfather in
        if(recoverableFonts.length > 0) {

            recoverableFonts.forEach(recoverableFontFamily => {
                console.log('restored', recoverableFontFamily)
                fonts.push({
                    family: recoverableFontFamily,
                    provider: 'cargo'
                });
            });

            Sentry.captureMessage('Restored legacy fonts', {
                extra: {
                    fonts: recoverableFonts
                }
            });

        }

        sharedAdminState.set('ranGrandfatheredFontCheck', true);

    }

    // kill dupes
    fonts = _.uniqWith(fonts, _.isEqual);

    if(!_.isEqual(state.site.fonts, fonts)) {

        const { CRDTItem: draftSiteModel } = getCRDTItem({
            reducer: 'site'
        });

        // set the new fonts on the site model
        draftSiteModel.set('fonts', Y.Array.from(fonts));

    }


}, 100);

export const initCSSSubscriptions = (state) => {

    const parser = getGlobalCSSParser(state.css.stylesheet);
    
    parser.subscribe('--text-style', updateTextStyleSubscriptions);

    // listen to font-family changes and see if we need to load custom fonts
    parser.subscribe('font-family', detectCustomFontChanges);

    let previousHasFontCollection = false;

    store.subscribe(() => {

        const state = store.getState();

        if(previousHasFontCollection === false && state.fontCollection.hasFontCollection === true) {
            detectCustomFontChanges();
            replaceDepricatedFonts();
        }

        previousHasFontCollection = state.fontCollection.hasFontCollection;

    })


}


