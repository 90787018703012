import { actionTypes } from "../actions";
import { combineReducers } from 'redux';

import { processFontCollection, handleComboFamilies, organizeFontCollection } from "@cargo/common/font-helpers.js";

import _ from "lodash";

const defaultFontCollectionState = {
	unprocessedCollection: [],
	collection          : [],
	collectionForRender : [],
	hasFontCollection   : false,
	fetchingFontCollection: true,
}

const variableSuperFamilies = {
	'Arizona': ['Serif', 'Text', 'Mix', 'Flare', 'Sans'],
	'Diatype': ['', 'Mono', 'Semi-Mono', 'Condensed', 'Compressed'],
	'Monument Grotesk': ['', 'Mono', 'Semi-Mono'],
	'Social': ['', 'Condensed'],
	'Gramercy': ['', 'Fine', 'Display'],
	'Synt': ['', 'Slant', 'Turbo']
};

const superFamilyClippingAxes = {
	// 'Diatype': {
	// 	'MONO':{
	// 		'greaterThan':0,
	// 		'lessThan': null,
	// 		'max':{
	// 			'wght': 700,
	// 		},
	// 		'min': {
	// 			'wdth': 100,
	// 		}
	// 	},
	// 	'wdth':{
	// 		'greaterThan':null,
	// 		'lessThan': 100,
	// 		'max': {
	// 			'wght': 700,
	// 			'MONO': 0,
	// 		},
	// 		'min': {}
	// 	},
	// 	'wght':{
	// 		'greaterThan':700,
	// 		'lessThan': null,
	// 		'max': {
	// 			'MONO': 0,
	// 		},
	// 		'min': {
	// 			'wdth': 100,
	// 		 }
	// 	}
	// },
	'Diatype': {
		'MONO':{
			'greaterThan':0,
			'lessThan': null,
			'max':{
				'wght': 700,
			},
			'min': {}
		},
		'wght':{
			'greaterThan':700,
			'lessThan': null,
			'max': {
				'MONO': 0,
			},
			'min': {}
		}
	},
	'Monument': {
		'MONO':{
			'greaterThan':0,
			'lessThan': null,
			'max': {
				'wght': 900,
			},
			'min': {}
		},
		'wght':{
			'greaterThan':900,
			'lessThan': null,
			'max': {
				'MONO': 0,
			},
			'min': { }
		},
	}
}

export default function(state = defaultFontCollectionState, action) {

	if( !action.payload && action.type !== actionTypes.FETCH_FONT_COLLECTION_PENDING ) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_FONT_COLLECTION_PENDING:
			
			const fetchState = { ...state, fetchingFontCollection: true}

			return fetchState
			
		case actionTypes.FETCH_FONT_COLLECTION:

			if(action.payload.data) {

				// Skipping processing means we're explicitly pulling the custom collection right after
				// and wanting to avoid a double data crunch.
				if( action.meta.skipProcessing ){
					const skipProcessingState = { ...state, unprocessedCollection: action.payload.data, hasFontCollection: false }
					return skipProcessingState;
				}

				// Prepair to render, first round of collection processing.
				let processedCollection = processFontCollection( action.payload.data );
				// Add combo families as a key in their "parent" family's object
				let handledCollection   = handleComboFamilies( processedCollection );
				// Organize font collection into major categories Staff Pick/ Serif/ Sans Serif/ Monospace
				let organizedCollection = organizeFontCollection( handledCollection );

				return {
					unprocessedCollection : action.payload.data,
					collection            : processedCollection,
					collectionForRender   : organizedCollection,
					variableSuperFamilies : variableSuperFamilies,
					superFamilyClippingAxes: superFamilyClippingAxes,
					hasFontCollection     : false
				}

			}

		case actionTypes.FETCH_FONT_COLLECTION_FULFILLED: 

			if(action.payload.data) {

				// We skip processing when we're pulling Cargo fonts for the picker before we pull custom fonts.
				// Additionally stops "hasFontCollection" from being set to true prematurely, as we need to wait for everything.
				// This is to avoid running through the font data organization functions more than once per fetch.
				// If we have no custom fonts, we should never skip processing.
				if( action.meta.skipProcessing ){
					const skipProcessingFulfilledState = { ...state, unprocessedCollection: action.payload.data, hasFontCollection: false }
					return skipProcessingFulfilledState;
				}

				let payloadData = action.payload.data;

				// Prepair to render, first round of collection processing.
				let processedCollection = processFontCollection( payloadData );
				// Add combo families as a key in their "parent" family's object
				let handledCollection   = handleComboFamilies( processedCollection );
				// Organize font collection into major categories Staff Pick/ Serif/ Sans Serif/ Monospace
				let organizedCollection = organizeFontCollection( handledCollection );

				return {
					unprocessedCollection : payloadData,					
					collection            : processedCollection,
					collectionForRender   : organizedCollection,
					variableSuperFamilies : variableSuperFamilies,
					superFamilyClippingAxes: superFamilyClippingAxes,
					hasFontCollection     : true,
					fetchingFontCollection: false
				}
			
			}

	}

	return state;

}