/**
 * @module bindings/dom
 */

/* global MutationObserver */

import * as Y from "yjs";
import { createAssociation, removeAssociation, domsToTypes, convertTypeToDom } from "./util.js";
import { defaultFilter, applyFilterOnType } from "./filter.js";
import { typeObserver } from "./typeObserver.js";
import { domObserver } from "./domObserver.js";
import MutationSummary from './mutation-summary';
import morphdom from '../../morphdom'

/**
 * @callback DomFilter
 * @param {string} nodeName
 * @param {Map<string, string>} attrs
 * @return {Map | null}
 */

/**
 * A binding that binds the children of a YXmlFragment to a DOM element.
 *
 * This binding is automatically destroyed when its parent is deleted.
 *
 * @example
 * const div = document.createElement('div')
 * const type = y.define('xml', Y.XmlFragment)
 * const binding = new Y.QuillBinding(type, div)
 *
 * @class
 */
export class DomBinding {
    /**
     * @param {Y.XmlFragment} type The bind source. This is the ultimate source of
     *                            truth.
     * @param {Element} target The bind target. Mirrors the target.
     * @param {Object} [opts] Optional configurations
     * @param {DomFilter} [opts.filter=defaultFilter] The filter function to use.
     * @param {Document} [opts.document=document] The filter function to use.
     * @param {Object} [opts.hooks] The filter function to use.
     * @param {Element} [opts.scrollingElement=null] The filter function to use.
     * @param {Boolean} [opts.initUsingDOM=null] Defines whether the binding is initated from a local DOM tree.
     */
    constructor(type, target, opts = {}) {

        // Binding handles textType as this.type and domTextarea as this.target
        /**
         * The Yjs type that is bound to `target`
         * @type {Y.XmlFragment}
         */
        this.type = type;
        /**
         * The target that `type` is bound to.
         * @type {Element}
         */
        this.target = target;
        /**
         * @private
         */
        this.opts = opts;
        opts.document = opts.document || document;
        opts.hooks = opts.hooks || {};
        this.scrollingElement = opts.scrollingElement || null;
        /**
         * Maps each DOM element to the type that it is associated with.
         * @type {WeakMap}
         */
        this.domToType = new WeakMap();
        /**
         * Maps each YXml type to the DOM element that it is associated with.
         * @type {WeakMap}
         */
        this.typeToDom = new WeakMap();

        // a queue of DOM nodes that are not associated yet
        this.domNodesAwaitingAssociation = new WeakMap();

        /**
         * Defines which DOM attributes and elements to filter out.
         * Also filters remote changes.
         * @type {DomFilter}
         */
        this.filter = opts.filter || defaultFilter;
        this.nodeFilter = opts.nodeFilter || (() => true);
        this.mutationFilter = opts.mutationFilter || (() => true);
        this.onNewNodeAssociated = opts.onNewNodeAssociated;

        /**
         * Defines which attributes to filter off specified elements
         * @type {DomFilter}
         */        
        this.attributeFilter = opts.attributeFilter || (() => true);

        // set initial value
        if(opts.initUsingDOM) {

            // duplicate entire target node into the shared type and don't
            // touch the existing dom, just read.
            const children = domsToTypes(
                this.target.childNodes, 
                document,
                opts.hooks,
                this.filter,
                this
            )
            
            this.type.insert(0, children)

        } else {

            // Init using shared DOM structure in the CRDT
            const oldTree = target;
            const newTree = convertTypeToDom(
                type,
                this.opts.document, 
                this.opts.hooks,
                this
            );

            morphdom(oldTree, newTree, {
                childrenOnly: true,
                onBeforeElUpdated: function(fromEl, toEl) {
                    // spec - https://dom.spec.whatwg.org/#concept-node-equals
                    if (fromEl.isEqualNode(toEl)) {
                        return false
                    }

                    return true
                }
            });

            // map DOM to YJS.
            // TODO: This won't work if a script tag mutates the DOM immediately. Find a way to work around this, perhaps
            // morphdom can give us a list of every node it touches
            const recurse = (domNode, yjsNode) => {

                if(!yjsNode) {
                    console.log('unable to associate dom node, no matching type found', domNode);
                    return;
                }

                for(let i = 0; i < domNode.childNodes.length; i++){

                    if(!yjsNode.get?.(i)) {
                        console.error('unable to associate dom node, no matching type found', domNode.childNodes[i]);
                        continue;
                    }

                    // node is in CRDT so assume it's saveable
                    if(domNode.childNodes[i].saveable !== true) {
                        domNode.childNodes[i].setSaveable(true);
                    }
                    
                    createAssociation(this, domNode.childNodes[i], yjsNode.get(i));

                    // there's children, recurse down.
                    if(domNode.childNodes[i].childNodes.length > 0) {
                        recurse(domNode.childNodes[i], yjsNode.get(i))
                    }

                }

            }

            recurse(target, type);

        }

        this._typeObserver = typeObserver.bind(this);
        this._domObserver = domObserver.bind(this);

        type.observeDeep(this._typeObserver);

        //keep track of the interval
        this._mutationInterval = null;

        this._mutationSummary = new MutationSummary({
            callback: this._domObserver,
            rootNode: this.target,
            queries: [{
                all: true
            }]
        });

        // immediately stop mutation summary from delivering mutations;
        this._mutationSummary.suspendDelivery();

        // then start the interval where we manually check for mutations
        if(opts.preventMutationObserverStart !== true) {
            this.startMutationObserver();
        }

        this.y = type.doc;

        // Force flush dom changes before Type changes are applied (they might
        // modify the dom)
        this.y.on("beforeTransaction", this._beforeTransactionHandler);
        this.y.on("afterTransaction", this._afterTransactionHandler);

        // Before calling observers, apply dom filter to all changed and new types.
        this.y.on("beforeObserverCalls", this._beforeObserverCallsHandler);

        createAssociation(this, target, type);

        target.dispatchEvent(new CustomEvent('dom-binding-initialized', {
            bubbles: true,
            cancelable: true,
            composed: false,
        }));

        target._dom_binding_initialized = true;

    }

    awaitDomNodeAssociation = node => {

        const existingType = this.domToType.get(node);

        if(existingType) {
            // Node is already associated with a type. Resolve immediately
            return Promise.resolve(existingType);
        }

        const existingAssociationObserver = this.domNodesAwaitingAssociation.get(node);

        if(existingAssociationObserver) {
            // Already have a promise for this node
            return existingAssociationObserver.promise
        }

        const promise = new Promise(resolve => {
            // add to the queue. We'll resolve this in `createAssociation`` in the helpers file
            this.domNodesAwaitingAssociation.set(node, {
                resolve
            });
        });

        this.domNodesAwaitingAssociation.get(node).promise = promise;

        return promise;

    }

    /**
     * NOTE:
     * * does not apply filter to existing elements!
     * * only guarantees that changes are filtered locally. Remote sites may see different content.
     *
     * @param {DomFilter} filter The filter function to use from now on.
     */
    setFilter(filter) {
        this.filter = filter;
        // TODO: apply filter to all elements
    }

    stopMutationObserver = (reportPendingChanges = false) => {

        if(this._mutationSummary.connected){

            // grab the remaining changes. We're not doing anything with these right now because
            // it looks like to only contain mutations from tearing page contents down, we don't 
            // want to sync this.
            const mutations = this._mutationSummary.getQueue();
            const summaries = this._mutationSummary.takeSummaries();

            this._mutationSummary.disconnect();

            if(
                reportPendingChanges 
                && summaries?.length > 0
                && this.mutationFilter(mutations)
            ){
                // if there are mutations, trigger the DOM observer
                this._domObserver(summaries);
            }

            if(this._mutationInterval){
                //stop the mutation interval if its' been started
                clearInterval(this._mutationInterval);
                this._mutationInterval = null;
            }
        }

    }

    startMutationObserver = () => {
        // if disconnected, reconnect
        if(!this._mutationSummary.connected){
            this._mutationSummary.reconnect();
            // immediately suspend mutation delivery when the observer's been started
            this._mutationSummary.suspendDelivery();
        }

        if(this._mutationInterval === null) {
            // start checking for mutations 
            this._mutationInterval = setInterval(()=> {
                // get a list of summaries
                const mutations = this._mutationSummary.getQueue();
                if(mutations.length > 0 && this.mutationFilter(mutations)){
                    // if there are mutations, trigger the DOM observer
                    this._domObserver(this._mutationSummary.takeSummaries());
                }
            }, 250)
        }

    }

    restoreSelection(selection) {
        
        // for now just skip this
        return;

        if (selection !== null) {
            const { to, from } = selection;
            /**
             * There is little information on the difference between anchor/focus and base/extent.
             * MDN doesn't even mention base/extent anymore.. though you still have to call
             * setBaseAndExtent to change the selection..
             * I can observe that base/extend refer to notes higher up in the xml hierachy.
             * Espesially for undo/redo this is preferred. If this becomes a problem in the future,
             * we should probably go back to anchor/focus.
             */
            const browserSelection = getSelection();
            let {
                baseNode,
                baseOffset,
                extentNode,
                extentOffset,
            } = browserSelection;
            if (from !== null) {
                let sel = Y.createAbsolutePositionFromRelativePosition(from, this.y);
                if (sel !== null) {
                    let node = this.typeToDom.get(sel.type);
                    let offset = sel.offset;
                    if (node !== baseNode || offset !== baseOffset) {
                        baseNode = node;
                        baseOffset = offset;
                    }
                }
            }
            if (to !== null) {
                let sel = Y.createAbsolutePositionFromRelativePosition(to, this.y);
                if (sel !== null) {
                    let node = this.typeToDom.get(sel.type);
                    let offset = sel.offset;
                    if (node !== extentNode || offset !== extentOffset) {
                        extentNode = node;
                        extentOffset = offset;
                    }
                }
            }
            browserSelection.setBaseAndExtent(
                baseNode,
                baseOffset,
                extentNode,
                extentOffset
            );
        }
    }

    /**
     * Remove all properties that are handled by this class.
     */
    destroy() {

        // do not handle any more changes to the type
        this.type.unobserveDeep(this._typeObserver);

        // stop the DOM observer and flush any pending changes.
        this.stopMutationObserver(true);

        // reset everything else
        this.domToType = new WeakMap();
        this.typeToDom = new WeakMap();
        this.type.doc.off("beforeTransaction", this._beforeTransactionHandler);
        this.type.doc.off("beforeObserverCalls", this._beforeObserverCallsHandler);
        this.type.doc.off("afterTransaction", this._afterTransactionHandler);
        this.type = null;
        this.target = document.createElement("empty");
    }

    _beforeTransactionHandler = (transaction, y) => {
    }

    _beforeObserverCallsHandler = (transaction, y) => {

        // Apply dom filter to new and changed types
        transaction.changed.forEach((subs, type) => {
            // Only check attributes. New types are filtered below.
            if (
                subs.size > 1 ||
                (subs.size === 1 && subs.has(null) === false)
            ) {
                applyFilterOnType(y, this, type);
            }
        });

    }

    _afterTransactionHandler = (transaction, y) => {
    }
}

/**
 * A filter defines which elements and attributes to share.
 * Return null if the node should be filtered. Otherwise return the Map of
 * accepted attributes.
 *
 * @callback FilterFunction
 * @param {string} nodeName
 * @param {Map} attrs
 * @return {Map|null}
 */
