import * as encoding from 'lib0/encoding'
import { OutgoingMessage } from '../OutgoingMessage.js'
import {
  MessageType,
  OutgoingMessageArguments,
} from '../types.js'

export class QueryAwarenessMessage extends OutgoingMessage {
  type = MessageType.QueryAwareness

  description = 'Queries awareness states'

  get(_args: Partial<OutgoingMessageArguments>) {
    encoding.writeVarUint(this.encoder, this.type)

    return this.encoder
  }
}
