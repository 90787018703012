/**
 * @module bindings/dom
 */

import * as Y from "yjs";
import {
    iterateUntilUndeleted,
    removeAssociation,
    insertNodeHelper,
    removeChildHelper,
    getAllDescendants,
    stringifyNodeIfTagNameIsIllegal
} from "./util.js";
import { simpleDiff } from "lib0/diff.js";

/**
 * 1. Check if any of the nodes was deleted
 * 2. Iterate over the children.
 *    2.1 If a node exists that is not yet bound to a type, insert a new node
 *    2.2 If _contents.length < dom.childNodes.length, fill the
 *        rest of _content with childNodes
 *    2.3 If a node was moved, delete it and
 *       recreate a new yxml element that is bound to that node.
 *       You can detect that a node was moved because expectedId
 *       !== actualId in the list
 *
 * @function
 * @private
 */
const applyChangesFromDom = (binding, dom, yxml) => {
    if (yxml == null || yxml === false || yxml.constructor === Y.XmlHook) {
        return;
    }

    // 1. Double check if any of the nodes was deleted
    const knownChildren = new Set();
    for (let i = dom.childNodes.length - 1; i >= 0; i--) {
        const type = binding.domToType.get(dom.childNodes[i]);
        if (type !== undefined && type !== false) {
            knownChildren.add(type);
        }
    }

    if(yxml.length > 0 && yxml instanceof Y.XmlElement){
        yxml.toArray().forEach(childType => {
            if(knownChildren.has(childType)) return;
            childType.parent.toArray().forEach((yxml, i) => {
                if(yxml === childType){
                    childType.parent.delete(i)
                }
            })
            removeAssociation(
                binding,
                binding.typeToDom.get(childType),
                childType
            );
        })
    }

    // 2. iterate
    const childNodes = dom.childNodes;
    const len = childNodes.length;
    let prevExpectedType = null;
    let expectedType = iterateUntilUndeleted(yxml._start);
    for (let domCnt = 0; domCnt < len; domCnt++) {
        const childNode = childNodes[domCnt];
        const childType = binding.domToType.get(childNode);

        if (childType !== undefined) {
            if (childType === false) {
                // should be ignored or is going to be deleted
                continue;
            }
            if (expectedType !== null) {
                if (expectedType !== childType._item) {
                    // 2.3 Not expected node
                    if (childType.parent !== yxml) {
                        // child was moved from another parent
                        // childType is going to be deleted by its previous parent
                        removeAssociation(binding, childNode, childType);
                    } else {
                        // child was moved to a different position.
                        removeAssociation(binding, childNode, childType);
                        childType.parent.toArray().forEach((yxml, i) => {
                            if(yxml === childType){
                                childType.parent.delete(i)
                            }
                        });
                    }

                    prevExpectedType = insertNodeHelper(
                        yxml,
                        prevExpectedType,
                        childNode,
                        binding.opts.document,
                        binding
                    );
                } else {
                    // Found expected node. Continue.
                    prevExpectedType = expectedType;
                    expectedType = iterateUntilUndeleted(expectedType.right);
                }
            } else {
                // 2.2 Fill _content with child nodes
                prevExpectedType = insertNodeHelper(
                    yxml,
                    prevExpectedType,
                    childNode,
                    binding.opts.document,
                    binding
                );
            }
        } else {
            // 2.1 A new node was found
            prevExpectedType = insertNodeHelper(
                yxml,
                prevExpectedType,
                childNode,
                binding.opts.document,
                binding
            );
        }
    }
};

/**
 * @private
 * @function
 */
export function domObserver(summaries) {

    if(!summaries || summaries.length === 0) {
        return;
    }

    if(!this.target.ownerDocument.contains(this.target)) {
        console.log('ignoring mutations that happened to detached target node', summaries);
        return;
    }

    // first handle attribute changes:
    this.type.doc.transact(() => {

        summaries.forEach(mutationSummary => {
            Object.keys(mutationSummary.attributeChanged).forEach(mutatedAttribute => {

                const affectedNodes = mutationSummary.attributeChanged[mutatedAttribute];

                affectedNodes.forEach(node => {
                    const yxml = this.domToType.get(node);

                    if (!yxml || yxml.constructor === Y.XmlFragment){
                        return;
                    }

                    const newAttributeValue = node.getAttribute(mutatedAttribute);

                    if ( !this.attributeFilter(node, mutatedAttribute) ) {
                        return
                    }

                    const oldAttributeValue = yxml.getAttribute(mutatedAttribute);
                    const attributes = new Map();
                    attributes.set(mutatedAttribute, newAttributeValue);
                    if(
                        this.filter(node.nodeName, attributes).size > 0
                    ) {
                        if(oldAttributeValue !== newAttributeValue) {
                            if(newAttributeValue == null){
                                yxml.removeAttribute(mutatedAttribute);
                            } else {
                                yxml.setAttribute(mutatedAttribute, newAttributeValue);
                            }
                        }
                    }
                })

            });
        })

    }, this);

    this.type.doc.transact(() => {

        summaries.forEach(mutationSummary => {

            const changedNodes = new Set();

            // then handle node changes
            [
                'added',
                'removed',
                'reordered',
                'reparented',
                'characterDataChanged'
            ].forEach(mutationType => {

                mutationSummary[mutationType].forEach(node => {

                    if(mutationType !== 'removed' && !this.nodeFilter(node)) {
                        return;
                    }

                    const yxml = this.domToType.get(node);

                    if (yxml === undefined) {
                        // In case yxml is undefined, we double check if we forgot to bind the dom
                        let parent = node;
                        let yParent;
                        do {
                            parent = parent.parentElement;
                            yParent = this.domToType.get(parent);
                        } while (yParent === undefined && parent !== null);
                        if (
                            yParent !== false &&
                            yParent !== undefined &&
                            yParent.constructor !== Y.XmlHook
                        ) {
                            changedNodes.add(parent);
                        }
                        return;
                    } else if (yxml === false || yxml.constructor === Y.XmlHook) {
                        // dom element is filtered / a dom hook
                        return;
                    }

                    // ingest node changes based on type
                    switch (mutationType) {
                        case "characterDataChanged":
                            var change = simpleDiff(yxml.toString(), node.nodeValue);
                            yxml.delete(change.index, change.remove);
                            yxml.insert(change.index, change.insert);
                            break;
                        case "added":
                            changedNodes.add(
                                stringifyNodeIfTagNameIsIllegal(node)
                            )
                            break;
                        case "removed":
                            removeChildHelper(this, mutationSummary.getOldParentNode(node), yxml, node)
                            break;
                        case "reordered":
                            // remove the node
                            removeChildHelper(this, node.parentNode, yxml, node);
                            // re-add in in the correct spot
                            changedNodes.add(node.parentNode);
                            changedNodes.add(node);

                            // handle any children
                            getAllDescendants(node, child => {
                                removeAssociation(this, child, this.domToType.get(child))
                                changedNodes.add(child)
                            });

                            break;
                        case "reparented":
                            // remove the node
                            removeChildHelper(this, mutationSummary.getOldParentNode(node), yxml, node);
                            // handle both the deleted parent and the new parent
                            changedNodes.add(mutationSummary.getOldParentNode(node));
                            changedNodes.add(node.parentNode);
                            changedNodes.add(node);

                            // handle any nested nodes
                            getAllDescendants(node, child => {
                                //if(child.nodeType === Node.ELEMENT_NODE && child.children.length > 0) {
                                    removeAssociation(this, child, this.domToType.get(child))
                                    changedNodes.add(child)
                                //}
                            })

                            break;
                    }

                });

            });

            // finally handle DOM structure changes
            changedNodes.forEach(dom => {
                const yxml = this.domToType.get(dom);
                applyChangesFromDom(this, dom, yxml);
            });

        });

    }, this);

};