import { getCRDTItem, hasCRDTItem } from "../multi-user/redux";
import { Y } from "../multi-user";
import _ from 'lodash';
import CargoCSSParser from "./cargo-css-parser";
import { setupSharedCSSListener } from './shared-css-listener';
import { YTransactionTypes } from "../../globals";

const getGlobalCSSParser = _.memoize((defaultStylesheet) => {

	let parser;

	if(hasCRDTItem({
		reducer: 'css',
		item: 'stylesheet'
	})) {

		const { CRDTItem } = getCRDTItem({
			reducer: 'css'
		})
		
		if(CRDTItem.get('stylesheet') instanceof Y.Text) {
			// we have a proper shared Y.Text instance
			parser = new CargoCSSParser(CRDTItem.get('stylesheet'), defaultStylesheet);
		}

	}

	if(!parser) {
		// ytext isn't in the CRDT yet. Start with a placeholder
		parser = new CargoCSSParser(new Y.Text(), defaultStylesheet);
	}

	// listen for the yText being inserted or removed from the CRDT
	setupSharedCSSListener(parser, { local: false });

	parser.onPendingUpdate = (pendingYText) => {

		const { CRDTItem } = getCRDTItem({
			reducer: 'css'
		});

		if(CRDTItem){

			ydoc.transact(() => {

				if(CRDTItem.get('stylesheet') instanceof Y.Text === false) {
					// and merge yText into the CRDT upon first edit
					CRDTItem.set('stylesheet', pendingYText);
				}
				
			}, YTransactionTypes.NotUndoable);

		} else {
			console.error("The CRDT item for this page couldn't be found.");
		}

	};

	return parser;

}, () => {
	// only run this once by always returning the same cache key
	return 'nocache'
});

export default getGlobalCSSParser;