import { actions } from "./actions";

let store;

export const useDarkMode = enable => {

	if(enable) {
		document.body.classList.add('dark');
		document.body.classList.remove('light');
	} else {
		document.body.classList.add('light');
		document.body.classList.remove('dark');
	}

	store.dispatch(actions.updateAdminState({
		darkMode: enable
	}));

}

export const toggleDarkMode = () => {
	if( document.body.classList.contains('dark') ){
		useDarkMode(false)
	} else {
		useDarkMode(true)
	}
}

export const initDarkMode = _store => {

	store = _store;

	let lastSelectedTheme;

	store.subscribe(() => {

		const state = store.getState();
		const currentSelectedTheme = state.user?.meta?.User_ColorTheme;

		if(currentSelectedTheme !== lastSelectedTheme) {
			
			// update this before we run another call to the store
			// and prevent an infinite looop
			lastSelectedTheme = currentSelectedTheme;

			if(currentSelectedTheme === null) {
				// revert to automatic dark mode
				useDarkMode(darkModeQuery.matches);
			} else {
				useDarkMode(currentSelectedTheme === "dark")
			}
		
		}

	})
	
	const darkModeMediaQueryCallback = () => {

		if(lastSelectedTheme !== null && lastSelectedTheme !== undefined) {
			// ignore the media query if we have a user preference
			return;
		}

		useDarkMode(darkModeQuery.matches);

	}

	const darkModeQuery = window.matchMedia(`(prefers-color-scheme: dark)`);
	darkModeQuery.addEventListener('change', darkModeMediaQueryCallback);

	// get initial media query state
	darkModeMediaQueryCallback();

};

