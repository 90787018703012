import { actionTypes } from "../actions";

import _ from "lodash";

let defaultSubscriptionsState = {
    requestPending: true
}

export default function(state = defaultSubscriptionsState, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_SUBSCRIPTIONS_FULFILLED: 

			return {
				...action.payload.data
			}

	}

	return state;

}