
import React, {useState, useEffect } from 'react';

export const LoggedOutDarkModeHandler = () => {
	
    useEffect(() => {

        const darkModeQuery = window.matchMedia(`(prefers-color-scheme: dark)`);
        darkModeMediaQueryCallback(darkModeQuery);
        darkModeQuery.addEventListener('change', darkModeMediaQueryCallback);

        return () => { 
            darkModeQuery.removeEventListener('change', darkModeMediaQueryCallback);
        }

    }, []);


	const darkModeMediaQueryCallback = (event) => {

        if(event.matches) {
            document.body.classList.add('dark');
            document.body.classList.remove('light');
        } else {
            document.body.classList.add('light');
            document.body.classList.remove('dark');
        }

	}

};

