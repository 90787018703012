import { parser as cssParser } from "./parsers/css/dist";
import _PropWatcher from './cargo-prop-watcher';
import { Observable } from 'lib0/observable';
import { TextStyleDefaults } from "../../defaults/text-style-defaults";
import { YTransactionTypes } from "../../globals";
import { metadata as CSSMetadata } from './CSSMetadata';

import { 
	findEmptySpaceTillNextDeclaration, 
	findEmptySpaceTillPreviousDeclaration
} from './helpers';

export default class CargoCSSParser extends Observable {

	// Define private fields
	#parsedErrors;
	#parser;
	#lastParsedCSS;
	#isParsing;
	#isRunningMutations;
	#nestedMutationDepth;
	#queuedMutations;
	#propertyChangeListeners;
	#subscriptionsByProperty;
	#initialCSSString;

	constructor(yText, initialCSSString = "") {

		super();

		this.#parser = cssParser;
		this.#parser.configure({
			strict: false,
			bufferLength: 1024
		});

		this.#isParsing = false;
		this.#isRunningMutations = false;
		this.#nestedMutationDepth = 0;
		this.#initialCSSString = initialCSSString;

		this.#queuedMutations = [];
		this.#propertyChangeListeners = [];

		this.#parsedErrors = [];
		this.#subscriptionsByProperty = new Map();

		this.propWatcherMap = new Map();

		this.observer = (e, transaction) => {

			if(this.#isRunningMutations) {
				return;
			}

			// whenever the shared type changes externally, re-parse the CSS.
			this.parseCSS();
		}

		// set the initial type
		this.updateType(yText);

	};

	updateType = (newType) => {

		// unobserve the current yText
		if(this.yText) {
			this.yText.unobserve(this.observer);
		}

		// set the yText
		this.yText = newType;

		if((this.yText.doc === null || this.yText.length === 0) && this.#initialCSSString) {
			this.yText.insert(0, this.#initialCSSString);
			this.#lastParsedCSS = this.#initialCSSString;
		} else {
			this.#lastParsedCSS = this.yText.doc === null ? '' : this.yText.toString();
		}

		// start observing 
		this.yText.observe(this.observer);

		// finally, parse the string to repopulate propWatchers with up-to-date values
		this.parseCSS();

	}

	isMutating = () => this.#isRunningMutations;

	mutateSharedType = (mutationFn, options = {}) => {

		// if the parser is running, delay modifications till parsing has completed. 
		// ensure that when the modification is complete, the parser first runs so it can update
		// indexes and catch errors
		if(mutationFn) {
			this.#queuedMutations.push(mutationFn);
		}

		if(this.#isParsing) {
			console.log('busy parsing');
			// Don't mutate the type whilst parsing, just queue the mutations.
			return;
		}

		if(this.#queuedMutations.length === 0) {
			return;
		}

		// take the pending mutations. Make a static copy so we don't handle new 
		// mutations that spawn from a parse in the loop below.
		const mutationsToRun = [...this.#queuedMutations];

		// Clear the mutation queue.
		this.#queuedMutations.splice(0);

		//console.log('handling', mutationsToRun.length, 'mutations');
		this.emit('mutationStart', []);

		this.#isRunningMutations = true;
		this.#nestedMutationDepth++;

		if(
			typeof this.onPendingUpdate === "function"
			&& (
				this.yText._pending?.length > 0
				|| this.yText.parent === null
			)
		){
			this.onPendingUpdate(this.yText);
		};

		while(mutationsToRun.length > 0) {

			// pull the first mutation from the queue
			const mutation = mutationsToRun.shift();

			if(this.yText.doc) {
				this.yText.doc.transact(() => {
					// run mutation
					mutation(this.yText);
				})
			} else {
				// run mutation
				mutation(this.yText);
			}

			// parse so the next mutation has up-to-date indexes.
			// We can improve perf by manually updating the indexes after a mutation, but 
			// for now this'll do.
			this.parseCSS();
			
		}

		// lower depth
		this.#nestedMutationDepth--;
		this.emit('mutationComplete', []);

		if(this.#nestedMutationDepth === 0) {
			this.#isRunningMutations = false;
			this.emit('allMutationsComplete', []);
		}

	}

	/*
	 * Subscribe to a property or a custom filter and get notified of additions and removals. Will fire
	 * a callback with a propWatcher for all found results and new additions.
	 */

	subscribe = (filter, callback) => {

		if(typeof filter === "string") {

			// when a string is supplied do a simple match based on the property, 
			// so addPropertyListener('font-size', () => {}) will return for all font-size
			// properties in the sheet

			const propertyToMatch = filter.toLowerCase();

			filter = (property, value, selectors) => {
				return property?.toLowerCase() === propertyToMatch;
			}

		} else if(typeof filter === "function") {

			const test = filter
			// TODO: implement a more custom search here. Not needed for now
			filter = (property, value, selectors) => {
				return test(property?.toLowerCase());
			}
		}


		this.#propertyChangeListeners.push({
			filter,
			callback
		})

		// console.log('starting custom filter', filter, this.#propertyChangeListeners);

		this.parseCSS();


	};

	unsubscribe = callback => {

		this.#propertyChangeListeners = this.#propertyChangeListeners.filter(subscription => subscription.callback !== callback);

		this.parseCSS();

	}

	getPropWatcher = (selector, propertyName, preventParse = false) => {

		if(!selector || !propertyName) {
			throw 'getPropWatcher: Missing selector or property'
			return;
		}
		
		const propWatcher = new _PropWatcher(
			selector, 
			propertyName,
			this
		);

		// if no entry for this selector, create one
		if(!this.propWatcherMap.has(selector)) {
			this.propWatcherMap.set(selector, new Map());
		}

		// the selector exists, but the property doesn't yet
		if(!this.propWatcherMap.get(selector).has(propertyName)) {
			this.propWatcherMap.get(selector).set(propertyName, []);
		}

		// get the selector, then the property, add the watcher to the property's watcher array
		this.propWatcherMap.get(selector).get(propertyName).push(propWatcher);

		propWatcher.on('destroy', (watcher) => {

			const parentArray = this.propWatcherMap.get(watcher.selector)?.get(watcher.propertyName);
			const watcherIndex = parentArray?.indexOf(watcher);

			// remove from internal map
			if(parentArray && watcherIndex !== -1) {
				parentArray.splice(watcherIndex, 1);
			}

		})

		if(!preventParse){
			// parse the CSS to populate the default value for this new propWatcher
			this.parseCSS();
		}

		return propWatcher;

	};

	getPropWatchers = (selectorWithPropertyArray) => {

		const propWatchers = selectorWithPropertyArray.map(({selector,property}) => this.getPropWatcher(selector, property, true));

		this.parseCSS();

		return propWatchers;

	}

	deletePropWatchers = (arrayOfPropWatchers) => {

		arrayOfPropWatchers.forEach(watcher => {
			this.deletePropWatcher(watcher, true);
		});

		// don't think we need to run a parse for deleted propWatchers
		//this.parseCSS(); // parse for multiple watchers 

	};

	getParsedRules = (selectorOrSelectors = null) => {

		const parserOptions = {
			parsedRuleArray: []
		}

		// we can pass in a list of selectors or a single selector. 
		if(typeof selectorOrSelectors === "string"){
			parserOptions.filterParsedRulesBySelector = selectorOrSelectors;
		} else {
			parserOptions.filterParsedRulesByMultipleSelectors = selectorOrSelectors;
		}

		// run a parse
		this.parseCSS(parserOptions);

		// grab the results
		return parserOptions.parsedRuleArray;

	} 

	deleteCSSRange = (start, end, opts = {
		includeLeadingLineBreaks: false,
		includeTrailingLineBreaks: false
	}) => {

		this.mutateSharedType(yText => {

			if(
				opts.includeLeadingLineBreaks 
				|| opts.includeTrailingLineBreaks
			){ 

				const cssString = yText.toString();

				if(opts.includeLeadingLineBreaks) {
					start = findEmptySpaceTillPreviousDeclaration(cssString, start, true, true);
				}

				if(opts.includeTrailingLineBreaks) {
					end = findEmptySpaceTillNextDeclaration(cssString, end, true, true);
				}

			}

			yText.delete(start, end - start)

		});

	}

	insertCSS = (CSSString, insertPosition = this.#lastParsedCSS.length) => {

		this.mutateSharedType(yText => {
			yText.insert(insertPosition, CSSString)
		});

	}

	insertNewColorSwatch = (color, swatchName, selector) => {

		// find the location of the selector
		const rules = this.getParsedRules(selector);

		if(rules[0]){
			const { parsedBlock } = rules[0];
			const { end } = parsedBlock;
			const declaration = `\t${swatchName}: ${color};\n`;

			this.mutateSharedType(yText => {
				yText.insert(end - 1, declaration);
			});
		}

	}

	clearCSS = () => {

		this.mutateSharedType(yText => {
			const emptyCSSString = '';
			yText.delete(0, yText.length);
			yText.insert(0, emptyCSSString);
			this.#lastParsedCSS = emptyCSSString;
		});

	}

	resetActiveSubscriptions = () => 
		this.#subscriptionsByProperty.forEach((bySelector, key, map) => 
			bySelector.forEach(stored => stored.active = false)
		)

	#serializeLezerNode = (cursor) =>

		cursor
			? {
				content: this.#lastParsedCSS.substring(cursor.from, cursor.to),
				start: cursor.from,
				end: cursor.to,
			  }
			: null;

	#gatherErrors = cursor => {

		this.#parsedErrors = []; // clears all parse errors from previous parse

		// if the current cursor is an error node, get the start, end, and content of the error node
		// and push to parsedErrors
		do {
			if(cursor.type.isError && cursor.node.from !== cursor.node.to){
				this.#parsedErrors.push(
					this.#serializeLezerNode(cursor.node)
				);
			};

		} while (cursor.next());

	};

	parseCSS = (options = {}) => {

		this.#isParsing = true;

		// set all subscriptions to active = false so we can detect if any have been removed at end of parse
		this.resetActiveSubscriptions();

		// clean all propwatchers, we will repopulate these values
		// if they exist in the newly parsed CSS.
		this.propWatcherMap.forEach((properties, selector) => {

			properties.forEach((watchers, property) => {
				watchers.forEach(watcher => {
					watcher.parsedValue = null;
					watcher.parsedDeclaration = null;
					watcher.parsedBlock = null;
					watcher.parsedRule = null;
					watcher.parsedMediaQueries = null;
					watcher.lastDeclarationInBlock = null;
					watcher.overriddenByShorthandValue = null;
					watcher.affectedByLonghandValues = null;
					watcher.affectedByShorthandValues = null;
				});
			});

		});

		// only use the yText when it's connected
		if(this.yText.doc !== null) {
			this.#lastParsedCSS = this.yText.toString();
		}

		this.emit('beforeParse', []);

		const parsedCSSTree = this.#parser.parse(this.#lastParsedCSS);

		// loop over the sheet and find all errors.
		this.#gatherErrors(parsedCSSTree.cursor());

		// get a cursor for our main loop
		const cursor = parsedCSSTree.cursor();
		let activeMediaQueryBlock = null;
		let parsedMediaQueries = null;

		do {

			// encountered a media query
			if(cursor.name === 'MediaStatement') {

				activeMediaQueryBlock = cursor.node.getChild('Block');
				// get a list of media queries applied to the current media query block
				parsedMediaQueries = cursor.node.getChildren("Queries").map(query => this.#serializeLezerNode(query.node));

			}

			// We're past the current media query block
			if(activeMediaQueryBlock && cursor.from > activeMediaQueryBlock.to) {

				// unset active media query block and parsed queries
				activeMediaQueryBlock = null;
				parsedMediaQueries = null;

			}

			if (cursor.name === "RuleSet") {

				//console.log({...cursor.type}, this.#serializeLezerNode(cursor.node))

				let errorsForRule = [];
				let storedRule = null;

				const parsedRule = this.#serializeLezerNode(cursor.node);
				const parsedBlock = this.#serializeLezerNode(cursor.node.getChild("Block"));

				if(!parsedRule || !parsedBlock) {
					continue;
				}

				// determine if this RuleSet node has any errors
				for (let i = 0; i < this.#parsedErrors.length; i++) {
					const error = this.#parsedErrors[i];
					if (parsedRule.start <= error.start && parsedRule.end >= error.start){
						errorsForRule.push(error);
					}
				}

				// find all selector types and return an array of them with start and end positions
				const parsedSelectors = cursor.node.getChildren("Selectors").map(
					this.#serializeLezerNode
				);

				// Store all parsed rules / selectors
				if(options.parsedRuleArray){

					if(options.filterParsedRulesBySelector){
						if(parsedSelectors.find(selector => parsedSelectors.length === 1 && selector.content.trim().toLowerCase() == options.filterParsedRulesBySelector.trim().toLowerCase())){
							storedRule = {
								parsedMediaQueries,
								parsedSelectors,
								parsedRule, 
								parsedBlock,
								parsedProperties: new Map()
							};
						}
					} else if (options.filterParsedRulesByMultipleSelectors && typeof options.filterParsedRulesByMultipleSelectors === "object"){
						options.filterParsedRulesByMultipleSelectors.forEach(storedSelector => {
							parsedSelectors.forEach(selector => {
								if(selector.content.trim().toLowerCase() === storedSelector.trim().toLowerCase()){
									storedRule = {
										parsedMediaQueries,
										parsedSelectors, 
										parsedRule, 
										parsedBlock,
										parsedProperties: new Map()
									};
								}
							});
						});
					} else {
						storedRule = {
							parsedMediaQueries,
							parsedSelectors,
							parsedRule,
							parsedBlock,
							parsedProperties: new Map()
						};
					}

				}

				const declarationNodes = cursor.node.getChild("Block") ? cursor.node.getChild("Block").getChildren("Declaration") : null;

				let lastDeclarationInBlock = null; 

				declarationNodes?.forEach((node, i) => {
					if(i === declarationNodes.length -1){
						const values = node.getChildren("Values");
						if(values.length > 0){
							const endPos = values[values.length -1].to;
							const hasTrailingSemicolon = this.#lastParsedCSS.substring(endPos, endPos + 1).includes(";");
							lastDeclarationInBlock = {
								endPos,
								hasTrailingSemicolon
							}
						}
					}
				});

				// find all property watchers associated to this block's selectors
				// and group them them by name 
				const associatedPropWatchers = parsedSelectors.reduce((map, parsedSelector) => {

					// this selector has property watchers
					if (this.propWatcherMap.has(parsedSelector.content)) {

						// add all watchers for this selector to the map
						this.propWatcherMap.get(parsedSelector.content).forEach((watchers, property) => {

							map[property] = [];

							watchers.forEach(watcher => {
								// add found watcher to the associatedPropWatchers map
								map[property].push(watcher);

								// set the rule and block nodes. These can exist without the propWatcher's value and declaration being there.
								watcher.parsedRule  = parsedRule;
								watcher.parsedBlock = parsedBlock;
								watcher.parsedMediaQueries = parsedMediaQueries;
								watcher.lastDeclarationInBlock = lastDeclarationInBlock;
							});

						});

					}

					return map;

				}, {});

				// If this block has multiple selectors we need to make sure we also match
				// propWatchers that match multiple selectors like "body, html"
				if(parsedSelectors.length > 1) {

					this.propWatcherMap.forEach((watchedProperties, watchedSelector) => {

						// This selector is comprised of multiple selectors
						if(watchedSelector.includes(',')) {

							const matchedWatcherSelectors = 
								// split the watched selector into the individual sub-selectors
								watchedSelector.split(',')
								// remove any trailing and leading whitespace
								.map(watcherSelectorPart => watcherSelectorPart.trim())
								// filter out all the selectors that do not match the current rule's selectors
								.filter(watcherSelectorPart => parsedSelectors.find(selector => selector.content === watcherSelectorPart))

							// if all of the current rule's selectors match exactly, match the propWatcher to this rule
							if(matchedWatcherSelectors.length === parsedSelectors.length) {

								watchedProperties.forEach((watchers, property) => {

									associatedPropWatchers[property] = [];

									watchers.forEach(watcher => {
										// add found watcher to the associatedPropWatchers map
										associatedPropWatchers[property].push(watcher);

										// set the rule and block nodes. These can exist without the propWatcher's value and declaration being there.
										watcher.parsedRule  = parsedRule;
										watcher.parsedBlock = parsedBlock;
										watcher.parsedMediaQueries = parsedMediaQueries;
										watcher.lastDeclarationInBlock = lastDeclarationInBlock;
									});

								});

							}

						}

					})

				}

				const previouslyMatchedProperties = [];
				const previouslyMatchedShorthands = new Map();
				const matchedPropertyChangeListeners = {};

				declarationNodes?.forEach(declarationNode => {

					const propertyNode = declarationNode.firstChild;
					const property = this.#serializeLezerNode(propertyNode).content;
					const values = declarationNode.getChildren("Values");

					//console.log(property, propertyNode)

					const parsedValue = {
						content: null,
						start: null,
						end: null,
					};

					// a declaration's value can be made up of multiple parts like "1px solid green"
					if(values.length > 0) {
						parsedValue.start = values[0].from;
						parsedValue.end = values[values.length - 1].to;
						parsedValue.content = this.#lastParsedCSS.substring(parsedValue.start, parsedValue.end);
					}

					if(storedRule) {
						storedRule.parsedProperties.set(property, parsedValue);
					}

					// shorthands can overwrite previously defined longhands
					// I.E. `padding` will override `padding-left` if defined after
					if(
						parsedValue.content !== null
						&& CSSMetadata.shorthands.includes(property)
					) {

						if(associatedPropWatchers.hasOwnProperty(property)) {
							previouslyMatchedShorthands.set(property, {
								watchers: associatedPropWatchers[property],
								serializedNode: this.#serializeLezerNode(declarationNode)
							});
						}

						// see if we encountered any longhands before
						previouslyMatchedProperties
							.filter(previouslyMatchedProperty => {
								// if we encountered any longhand that belongs to this shorthand, match it
								return CSSMetadata.byShortHand[property].includes(previouslyMatchedProperty);
							}).forEach(overRiddenProperty => {
								
								// for every matched property that got overridden, add it to the list
								associatedPropWatchers[overRiddenProperty].forEach(watcher => {
									watcher.overriddenByShorthandValue = parsedValue;
								});

							})

					} else {

						// check if this longhand is overriding part of a previously defined shorthand. 
						// I.E. `padding-left` will partly override `padding` if defined after
						for (const [shorthand, shorthandData] of previouslyMatchedShorthands) {
							if(CSSMetadata.byShortHand[shorthand].includes(property)) {


								if(associatedPropWatchers.hasOwnProperty(property)) {
									associatedPropWatchers[property].forEach(matchedPropWatcher => {
										// let these longhand watchers know that they have a shorthand
										// affecting them.
										matchedPropWatcher.affectedByShorthandValues = matchedPropWatcher.affectedByShorthandValues || {};

										matchedPropWatcher.affectedByShorthandValues[shorthand] = shorthandData.serializedNode;
									});
								}

								shorthandData.watchers.forEach(watcher => {
									// initialize field if still null
									watcher.affectedByLonghandValues = watcher.affectedByLonghandValues || {};

									// tell the shorthand propwatcher that this longhand is affecting it
									watcher.affectedByLonghandValues[property] = this.#serializeLezerNode(declarationNode);
								});

								break;
							}
						}
					}

					// see if there's any watchers associated with this property
					if(associatedPropWatchers.hasOwnProperty(property)) {
						
						associatedPropWatchers[property].forEach(matchedPropWatcher => {
						
							// store references to the parsed nodes lezer gave us.
							matchedPropWatcher.parsedDeclaration 	= this.#serializeLezerNode(declarationNode);
							matchedPropWatcher.parsedValue 			= parsedValue;

							// if the cursor has an error, let the watcher know
							matchedPropWatcher.errors = errorsForRule;

						});

						// add this property to the list of matched properties
						previouslyMatchedProperties.push(property);

					}

					// Match property with any potential subscribers
					const subscribedListeners = this.#propertyChangeListeners.filter(listener => listener.filter(property));

					if(subscribedListeners.length > 0) {
						// Overwrite older matches as we only want to match the last property in the same rule 
						// in case the same property is defined twice
						matchedPropertyChangeListeners[property] = [subscribedListeners, parsedValue]
					}

				});

				if(Object.keys(matchedPropertyChangeListeners).length > 0) {

					for (const [property, [listeners, parsedValue]] of Object.entries(matchedPropertyChangeListeners)) {

						//First check in the subscribed properties if a property has been stored
						parsedSelectors.forEach(selector => {
							// iterate through each selector in the parsed rule
							// see if there's a subscribed value stored for the selector
							const subscriptions = this.#subscriptionsByProperty.get(property)

							// prepare the stored values
							const stored = {
								selector,
								property,
								value: parsedValue.content,
								parent: parsedBlock,
								active: true
							}

							if(!subscriptions){
								// if a map doesn't exist for this property yet, create a new map and store the value
								const propertiesBySelector = new Map();
								propertiesBySelector.set(selector.content, stored)

								this.#subscriptionsByProperty.set(property, propertiesBySelector);

								listeners.forEach(listener => {
									listener.callback({
										...stored,
										type: "added",
									});
								});

							} else {
								// get the stored value for the selector
								const subscription = subscriptions.get(selector.content);

								if(subscription && subscription.value !== parsedValue.content){
									// if the stored value is different than parsed value, trigger an update and store the new value
									subscriptions.delete(selector.content);
									subscriptions.set(selector.content, stored);

									listeners.forEach(listener => {
										listener.callback({
											...stored,
											oldValue: subscription.value,
											type: "updated"
										});
									});

								} else if(!subscription){
									// if this is a new subscription, trigger "added" update and store it
									subscriptions.set(selector.content, stored);
									listeners.forEach(listener => {
										listener.callback({
											...stored,
											type: "added"
										});
									});

								} else {
									// the subscription is active in stylesheet, but not added or updated
									subscription.active = true;
								}
							}
						});

					}

				}

				if(storedRule) {
					options.parsedRuleArray.push(storedRule);
				}

			}
		} while (cursor.next());

		this.#isParsing = false;

		// find inactive subscriptions and fire a "removed" event
		this.#subscriptionsByProperty.forEach((subscriptionBySelector, property) => {

			subscriptionBySelector.forEach((match, selector) => {

				if(match.active === false) {
					
					// this subscription match is no longer found. Fire a remove event for attached listeners
					const subscribedListeners = this.#propertyChangeListeners.filter(listener => listener.filter(property));

					subscribedListeners.forEach(listener => {
						listener.callback({
							...match,
							type: "removed"
						})
					})

					subscriptionBySelector.delete(selector);

				}

			});

		});

		this.emit('afterParse', []);

	};
}

