import { createSelector } from 'reselect';
import { CRDTState } from "./globals";
import _ from 'lodash';

const selectors = {};

selectors.getSortedAdminList = createSelector(
	(state) => state,
	(state) => state.sets.byId,
	(state) => state.pages.byId,
	(state) => state.structure.bySort,
	// compile the result
	(state, setList, pageList, sortById) => {

		let sortedList = _.values({...pageList, ...setList}).sort((a, b) => {
			// root has a sort index of undefined. In that case make sure we still
			// use a number otherwise sort will trip up in Safari / FF
			return (sortById[a.id] || -1) - (sortById[b.id] || -1)
		});

		// filter any deleted pages and root
		sortedList = sortedList.filter(content => {
			return content.crdt_state !== CRDTState.Deleted && content.id !== 'root'
		});

		return sortedList

	}
)


selectors.getSortedAdminListByParent = createSelector(
	(state) => state,
	selectors.getSortedAdminList,
	// compile the result
	(state, sortedList) => {

		return _.groupBy(sortedList, content => selectors.getItemParentId(state, content.id))

	}
)

export default selectors