import { pathToRegexp, Key } from 'path-to-regexp'
import * as Sentry from '@sentry/browser'
import {
  onStatelessTaskParameters,
  StatelessTaskHandler,
  StatelessTask,
} from './types.js'

export class StatelessRoute {
  task: StatelessTask
  keys: Array<Key>
  regex: RegExp
  handler: StatelessTaskHandler

  /**
   * Constructor
   */
  constructor(task: StatelessTask, handler: StatelessTaskHandler) {
    this.task = task
    this.keys = []
    this.regex = pathToRegexp(`${task}/:messageId/:details(.*)?`, this.keys)
    this.handler = handler
  }

  match(payload: string): boolean {
    return this.regex.test(payload)
  }

  parse(payload: string): onStatelessTaskParameters {
    const parameters: onStatelessTaskParameters = {
      payload: payload,
      task: this.task,
      messageId: '',
    }

    const result = this.regex.exec(payload)
    if (result === null) {
      Sentry.captureMessage('[Stateless] Parameters unparsed', { extra: parameters })
      return parameters
    }

    for (let i = 1; i < result.length; i++) {
      try {
        const key = this.keys[i - 1].name
        if (key === 'messageId') {
          parameters.messageId = decodeURIComponent(result[i])
        } else if (key === 'details') {
          if (result[i] !== undefined) {
            parameters.details = JSON.parse(result[i])
          }
        }
      } catch (error) {
        Sentry.captureMessage('[Stateless] Parameters error', {
          extra: {
            parameters: parameters,
            result: result,
            error: (error as unknown as Error)?.message,
          }
        })
        // URIError or JSONError
        console.warn(`Unable to parse stateless message: ${error}`)
      }
    }

    return parameters
  }

  process(payload: string): void {
    const parameters = this.parse(payload)
    this.handler(parameters)
  }
}
