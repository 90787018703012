import { actionTypes } from "../actions";
import { combineReducers } from 'redux';

import _ from "lodash";

const byId = function(state = {}, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.ADD_UIWINDOW:

			return {
				...state,
				[action.payload.id]: action.payload
			}

		break;
		case actionTypes.UPDATE_UIWINDOW:

			// look up the existing window in the current state
			const existingUIWindow = state[action.meta.id];

			if(!existingUIWindow) {
				// nothing to update. Do nothing
				return state;
			}

			// return a new state object with the updated UIWindow
			return {
				...state,
				[action.meta.id]: {
					...existingUIWindow,
					props: {
						...existingUIWindow.props,
						...action.payload
					}
				}
			};

		break;
		case actionTypes.DELETE_UIWINDOW:

			const newState = {...state};

			_.each(action.payload, windowToRemove => {
				delete newState[windowToRemove.id]
			});

			return newState;

		break;

	}

	return state;

}

const byGroup = function(state = {}, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.ADD_UIWINDOW:

			const newStateSlice = {
				[action.payload.group]: [action.payload.id]
			};

			return _.mergeWith({}, state, newStateSlice, (objValue, srcValue) => {
				if (_.isArray(objValue)) {
					return _.uniq(objValue.concat(srcValue));
				}
			});

		break;
		case actionTypes.DELETE_UIWINDOW:

			const newState = {};

			_.each(action.payload, windowToRemove => {
				// remove the file id from the old parent folder list
				if(state[windowToRemove.group]) {
					newState[windowToRemove.group] = state[windowToRemove.group].filter(id => id !== windowToRemove.id)
				}
			});

			return _.assign({}, state, newState);

		break;
	}

	return state;

}

const focusOrder = function(state = [], action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.ADD_UIWINDOW:

			return [action.payload.id, ...state];

		break;
		case actionTypes.DELETE_UIWINDOW:

			let newState = [...state];

			_.each(action.payload, windowToRemove => {
				newState = newState.filter(id => id !== windowToRemove.id);
			});

			return newState;

		break;
	}

	return state;

}

export default combineReducers({
	byId,
	byGroup,
	focusOrder
})