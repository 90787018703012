import _ from 'lodash';
import { Observable } from 'lib0/observable'
import { FRONTEND_DATA } from "../globals";
import { store } from "../index";
import { helpers } from "@cargo/common";


class DragEventController extends Observable {

	constructor() {
		super();

		// whether drag originated from outside window - drags from outside window do not trigger dragstart
		this.fromOutside = true;
		this.fromAdmin = false;
		this.inAdmin = false;

		this.dragRange = null;
		this.draggedMediaItems = []
		this.draggedNodes = [];
		this.dataTransfer = new Map();

		// When setting the drag image natively, use this object to cache the image and where we clicked it
		this.dragImage = {
			el: null,
			custom: false,
			rect: {
				x: 0,
				y: 0,
				w: 0,
				h: 0
			}
		};

		this.dragEndHandled = false;
		this.dragDropHandled = false;
		this.dragStartHandled = false;

		this.dragover = this.onDragOver;
		this.dragleave = this.onDragLeave;
		this.dragenter = this.onDragEnter;
		this.dragstart = this.onDragStart;
		this.dragend = this.onDragEnd;
		this.drop = this.onDragDrop;

		this.blankImg = document.createElement('img');
		this.blankImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
		this.blankImg.setAttribute('width', 100)
		this.blankImg.setAttribute('height', 100)

		this.onWindowDragOver = (e)=>{
			this.testIfEventInAdmin(e);
			// e.preventDefault()
			this.onDragOver(e);
		}

		this.onWindowDragStart = (e)=>{
			this.testIfEventInAdmin(e);
			this.onDragStart(e);
		}

		this.onWindowDragEnd = (e)=>{
			this.testIfEventInAdmin(e);
			this.onDragEnd(e);
		}

		this.onWindowDragDrop = (e)=>{
			this.testIfEventInAdmin(e);
			this.onDragDrop(e);			
		}

		this.onWindowDragLeave = (e)=>{
			this.onDragLeave(e);
		}


		if( FRONTEND_DATA.contentWindow?.CargoEditor && Object.keys(FRONTEND_DATA.contentWindow?.CargoEditor || {}).length > 0 ){
			this.bindEditorEvents();
		} else {
			window.addEventListener('CargoEditor-load', this.bindEditorEvents);
		}


		this.adminMode = true;
		this.findStore().then(()=>{
			this.unsubscribeFromStore = store.subscribe(this.handleStoreChange);
			this.handleStoreChange();
		}).catch((e)=>{
			this.adminMode = false;
		})
		
	}

	testIfEventInAdmin= (e)=>{
		this.inAdmin = document.contains(e.target) && !e.target.classList?.contains('clickout-layer');
	}

	bindEditorEvents= ()=>{

		window.removeEventListener('CargoEditor-load', this.bindEditorEvents);


		this.CargoEditor = FRONTEND_DATA.contentWindow.CargoEditor;

		FRONTEND_DATA.contentWindow.addEventListener('pointermove', (e)=>{
			this.x = e.clientX;
			this.y = e.clientY;
		})

		FRONTEND_DATA.contentWindow.document.addEventListener('dragover', this.onDragOver);			
		FRONTEND_DATA.contentWindow.document.addEventListener('dragstart', this.onDragStart);
		FRONTEND_DATA.contentWindow.document.body.addEventListener('dragenter', this.onDragEnter);		
		FRONTEND_DATA.contentWindow.document.body.addEventListener('dragleave', this.onDragLeave);
		FRONTEND_DATA.contentWindow.document.addEventListener('drop', this.onDragDrop);			
		FRONTEND_DATA.contentWindow.document.addEventListener('dragend', this.onDragEnd);

		window.addEventListener('dragover', this.onWindowDragOver);			
		window.addEventListener('dragstart', this.onWindowDragStart);
		window.addEventListener('drop', this.onWindowDragDrop);			
		window.addEventListener('dragend', this.onWindowDragEnd);

	}

	handleStoreChange = ()=>{
		this.adminMode = store.getState().frontendState.adminMode;
	}


	trigger = (eventName, editor, e) =>{
		if ( typeof e !== 'object'){
			e = {
				synthetic: true,
				type: eventName,
				clientX: this.x,
				clientY: this.y,
				target: document.elementFromPoint(this.x, this.y),
				defaultPrevented: false,
				preventDefault: function(){this.defaultPrevented = true},
				stopPropagation: ()=>{},
				stopImmediatePropagation: ()=>{}
			}
		}
		if( !editor ){

			if( this.CargoEditor){
				editor = this.CargoEditor.getActiveEditor();
			} else {
				return
			}
			
		}

		if( this[eventName] ){
			this[eventName](editor, e);
		}
	}

	findStore = ()=>{

		return new Promise((resolve, reject)=>{

			let findStoreAttempts = 0;
			let checkStoreInterval = null;
			const findStore = ()=>{

				if( !store ){
					findStoreAttempts++
				} else {
					clearInterval(checkStoreInterval);					
					resolve(store);
				}

				if(findStoreAttempts > 200){
					clearInterval(checkStoreInterval);
					reject();
				}
			};

			checkStoreInterval = setInterval(findStore, 300)
			findStore();

		});	
	
	}	

	onDragEnter = (e)=>{

		if( !this.checkForEditorAndAdminMode() ){
			return;
		}
		let editor = this.CargoEditor.getActiveEditor();

		this.emit('dragenter', [editor, e, {
			fromOutside: this.fromOutside,
			fromAdmin: this.fromAdmin,
			inAdmin: this.inAdmin,
			dragRange: this.dragRange,
			draggedNodes: this.draggedNodes,
			draggedMediaItems: this.draggedMediaItems,
			dataTransfer: this.dataTransfer,
			dragImage: this.dragImage,
			cursorPosition: this.getCursorPos(e),
		}]);	

	}

	onDragOver = (e)=>{
		
		if( !this.checkForEditorAndAdminMode() ){
			return;
		}

		if( e ){
			if( !e.synthetic){
				e.preventDefault();	
				if (e.dataTransfer){
					e.dataTransfer.dropEffect = 'move';
					e.dataTransfer.effectAllowed = 'all';
				}
				this.x = e.clientX;
				this.y = e.clientY;
			}
			this.testIfEventInAdmin(e);
		}

		let editor = this.CargoEditor.getActiveEditor();

		if(this.dragImage.custom){
			if(!this.ticking){
				this.drawFrame = requestAnimationFrame(()=>{
					const cursorPosition = this.getCursorPos();					
					this.dragImage.el.style.transform = `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`;
					this.ticking = false;
				})
			}
			this.ticking = true;
		}

		const {insertionRange, dropTarget} = this.getInsertionRangeAndDropTargetFromCoordinates(e, editor);

		this.emit('dragover', [editor, e, {
			fromOutside: this.fromOutside,
			fromAdmin: this.fromAdmin,
			inAdmin: this.inAdmin,
			dragRange: this.dragRange,
			insertionRange: insertionRange,
			draggedNodes: this.draggedNodes,
			draggedMediaItems: this.draggedMediaItems,
			customDropTarget: this.customDropTarget,
			dataTransfer: this.dataTransfer,
			dropTarget: dropTarget,
			dragImage: this.dragImage,
			cursorPosition: this.getCursorPos(e),
		}]);

	}

	onDragStart = (e)=>{

		if( !this.checkForEditorAndAdminMode() ){
			return;
		}

		if( e ){
			if( !e.synthetic){
				if (e.dataTransfer){
					e.dataTransfer.dropEffect = 'move';
					e.dataTransfer.effectAllowed = 'all';
				}
			}
		}		
	
		this.fromOutside = false;
		this.fromAdmin = this.inAdmin;

		if( this.dragStartHandled ){
			return
		}

		this.dragStartHandled = true;

		let editor = this.CargoEditor.getActiveEditor();
		let range = this.CargoEditor.getActiveRange();
		const editorEl = editor.getElement();

		if( range ){
			range = range.cloneRange();
		} else {
			range = this.CargoEditor.rangy.createRange();
		}
		if(e?.target && !range.intersectsNode(e.target) ){
			range.selectNode(e.target)
		}

		let containingCaption = range.startContainer.nodeType === Node.TEXT_NODE ? range.startContainer.parentElement : range.startContainer;
		containingCaption = containingCaption.closest('FIGCAPTION');

		// then check end container
		if( !containingCaption ){
			containingCaption = range.endContainer.nodeType === Node.TEXT_NODE ? range.endContainer.parentElement : range.endContainer;
			containingCaption = containingCaption.closest('FIGCAPTION') 
		}

		// don't drag media item if the range is inside a caption area
		if(
			containingCaption
		){

			let captionContainsStart = containingCaption.contains(range.startContainer);
			let captionContainsEnd = containingCaption.contains(range.endContainer);			

			if( !captionContainsStart){
				range.setStart(containingCaption, 0)	
			}

			if( !captionContainsEnd ){
				range.setEnd(containingCaption, containingCaption.childNodes.length);
			}

		} 

		const draggedNodes = range.getNodes();
		let topLevelNodesInRange = null;

		if( range.commonAncestorContainer.nodeType === Node.TEXT_NODE){
			topLevelNodesInRange = draggedNodes;
		} else {
			topLevelNodesInRange = draggedNodes.filter(node=>{
				return node.parentNode == range.commonAncestorContainer
			})			
		}

		// when generating a list of dragged nodes, make sure they are part of the editor if the drag came from the content window
		let draggedMediaItems = draggedNodes.filter(el=>el.nodeName==='MEDIA-ITEM')

		if( !this.fromAdmin ){
			topLevelNodesInRange = topLevelNodesInRange.filter(node=>editorEl.contains(node));
			draggedMediaItems = draggedMediaItems.filter(node=>editorEl.contains(node));			
		}


		this.dragRange = range;
		this.draggedMediaItems = draggedMediaItems;
		this.dragContainsLinks = draggedNodes.find(node=>node.nodeName =='A') ? true: false;
		this.draggedNodes = topLevelNodesInRange;

		// clear selections(?) to prevent selection from showing on drag image
		// commented out because it causes selection issues
		// window.getSelection().removeAllRanges();

		// if we're dragging a single media figure, set and cache its drag image:


		if(
			this.draggedMediaItems.length == 1 &&
			this.draggedNodes.length == 1 &&
			e &&
			!isNaN(e?.clientX) &&
			!isNaN(e?.clientY) &&
			e.target && editor.getElement().contains(e.target)
		){

			const mediaItem = this.draggedMediaItems[0];
			const mediaItemMedia = mediaItem.querySelector('.sizing-frame *');
			const mediaItemRect = mediaItem.getBoundingClientRect();

			if(this.dragImage.custom){
				this.dragImage.el?.remove();
			}
		

			this.dragImage = {
				el: mediaItem,
				custom: false,
				rect: {
					x: e.clientX - mediaItemRect.left,
					y: e.clientY - mediaItemRect.top,
					w: mediaItem.offsetWidth,
					h: mediaItem.offsetHeight
				}
			}

			if( e.dataTransfer){

		        // if( helpers.isSafari() || helpers.isFirefox() || window.devicePixelRatio ===1 ){
		            e.dataTransfer.setDragImage(this.dragImage.el, (this.dragImage.rect.x)  , (this.dragImage.rect.y) )
		        // } else {
		            // e.dataTransfer.setDragImage(this.dragImage.el, (this.dragImage.rect.x) * 2  , (this.dragImage.rect.y) * 2 )
		        // }				
			}

			if(!this.inAdmin){
				this.scrollParent = this.CargoEditor.getActiveEditor().getElement().closest('.page.allow-scroll') ? this.CargoEditor.getActiveEditor().getElement().closest('.page-content') : FRONTEND_DATA.contentWindow.document.scrollingElement
				this.scrollParent.style.overflow = 'hidden';
				this.edgeScrollFrame = requestAnimationFrame(this.edgeScroll)
			}

		} else {

			this.dragImage = {
				el: null,
				custom: false,
				rect: {
					x:0,
					y:0,
					w:0,
					h:0,
				}
			}

		}

		// make sure we're not dragging bits of ui out of the window
		if( this.fromAdmin ){
			this.draggedNodes = this.draggedNodes.filter(node=>!document.contains(node))
		}


		this.emit('dragstart', [editor, e, {
			fromOutside: this.fromOutside,
			fromAdmin: this.fromAdmin,
			inAdmin: this.inAdmin,
			dragRange: this.dragRange,
			draggedNodes: this.draggedNodes,
			draggedMediaItems: this.draggedMediaItems,
			dataTransfer: this.dataTransfer,
			dragImage: this.dragImage,
			cursorPosition: this.getCursorPos(e),
		}]);

		// modifying the dom (eg. removing dragged nodes) will upset and cancel drag events, so instead we can defer and listening elements can remove dragged items afterwards
		_.defer(()=>{
			this.emit('after-dragstart', [editor, e, {
				fromOutside: this.fromOutside,
				fromAdmin: this.fromAdmin,
				inAdmin: this.inAdmin,
				dragRange: this.dragRange,
				draggedNodes: this.draggedNodes,
				draggedMediaItems: this.draggedMediaItems,
				dataTransfer: this.dataTransfer,
				dragImage: this.dragImage,
				cursorPosition: this.getCursorPos(e),
			}]);			
		})

		this.windowHeight = FRONTEND_DATA.contentWindow.document.documentElement.clientHeight;
	}

	onDragLeave = (e)=>{
		

		if( !this.checkForEditorAndAdminMode() ){
			return;
		}
		let editor = this.CargoEditor.getActiveEditor();	

		this.emit('dragleave', [editor, e, {
			fromOutside: this.fromOutside,
			fromAdmin: this.fromAdmin,
			inAdmin: this.inAdmin,
			dragRange: this.dragRange,
			draggedNodes: this.draggedNodes,
			draggedMediaItems: this.draggedMediaItems,
			dataTransfer: this.dataTransfer,
			dragImage: this.dragImage,
			cursorPosition: this.getCursorPos(e),
		}]);	
	}

	onDragEnd = (e)=>{

		if( this.scrollParent ){ this.scrollParent.style.overflow = '' };
		cancelAnimationFrame(this.edgeScrollFrame)
		if( !this.checkForEditorAndAdminMode() ){
			return;
		}
		if( this.dragEndHandled ){
			return;
		}
		this.dragEndHandled = true;
		let editor = this.CargoEditor.getActiveEditor();

		this.emit('dragend', [editor, e, {
			fromOutside: this.fromOutside,
			fromAdmin: this.fromAdmin,
			inAdmin: this.inAdmin,
			dragRange: this.dragRange,
			draggedNodes: this.draggedNodes,
			draggedMediaItems: this.draggedMediaItems,
			dataTransfer: this.dataTransfer,
			dragImage: this.dragImage,
			cursorPosition: this.getCursorPos(e),
		}]);

		this.dataTransfer.clear();
		this.customDropTarget = null;
		this.draggedMediaItems = [];
		this.draggedNodes = [];
		this.dragRange = null;

		if(this.dragImage.custom){
			this.dragImage.el.remove();
		}
		this.dragImage = {
			el: null,
			rect: {
				x: 0,
				y: 0,
				w: 0,
				h: 0,
			}
		}		
		_.defer(()=>{
			this.dragStartHandled = false;	
			this.dragEndHandled = false;
			this.inAdmin = false;
			this.fromAdmin = false;
		});

		cancelAnimationFrame(this.drawFrame);
		this.fromOutside = true;
		this.ticking = false;
	}

	onDragDrop = (e)=>{

		if( this.scrollParent ){ this.scrollParent.style.overflow = '' };
		cancelAnimationFrame(this.edgeScrollFrame)
		
		if( !this.checkForEditorAndAdminMode() ){
			return;
		}
		if( this.dragDropHandled ){
			return;
		}

		if( e ){
			if (e.dataTransfer){
				e.dataTransfer.dropEffect = 'move';
				e.dataTransfer.effectAllowed = 'all';
			}

		}			

		this.dragDropHandled = true;

		let editor = this.CargoEditor.getActiveEditor();

		e?.preventDefault?.();

		const {insertionRange, dropTarget} = this.getInsertionRangeAndDropTargetFromCoordinates(e, editor);


		// gather files from file list and place it in dataTransfer map
		// if the drop is happening from admin, don't allow file transfers
		const droppedFiles = e?.dataTransfer?.files;
		if(droppedFiles && !this.fromAdmin){
			this.dataTransfer.set('files', Array.from(droppedFiles));
			this.emit('retrieve-files', [editor, e, {
				dataTransfer: this.dataTransfer
			}])
		}

		const cursorPos = this.getCursorPos(e);
		const lastDroppedNode = this.draggedNodes[this.draggedNodes.length-1];


		// galleries or other elements have the opportunity to make themselves the drop target or
		// modify incoming dragged nodes by listening to this event
		this.emit('before-drop', [editor, e, {
			fromOutside: this.fromOutside,
			fromAdmin: this.fromAdmin,
			inAdmin: this.inAdmin,
			dropTarget:dropTarget,
			dragRange: this.dragRange,
			insertionRange: insertionRange,
			draggedNodes: this.draggedNodes,
			draggedMediaItems: this.draggedMediaItems,
			customDropTarget: this.customDropTarget,
			dataTransfer: this.dataTransfer,
			dragImage: this.dragImage,
			cursorPosition: cursorPos,
		}]);

		this.emit('drop', [editor, e, {
			fromOutside: this.fromOutside,
			fromAdmin: this.fromAdmin,
			inAdmin: this.inAdmin,
			dragRange: this.dragRange,
			insertionRange: insertionRange,
			draggedNodes: this.draggedNodes,
			draggedMediaItems: this.draggedMediaItems,
			dropTarget:dropTarget,			
			customDropTarget: this.customDropTarget,
			dataTransfer: this.dataTransfer,
			dragImage: this.dragImage,
			cursorPosition: cursorPos,
		}]);

		this.dataTransfer.clear();

		if( this.dragImage.custom){
			this.dragImage.el.remove();
		}


		this.dragImage = {
			el: null,
			custom: false,
			rect: {
				x: 0,
				y: 0,
				w: 0,
				h: 0,
			}
		}

		this.draggedMediaItems = [];
		this.draggedNodes = [];
		this.dragRange = null;
		this.customDropTarget = null;
		this.fromOutside = true;
		cancelAnimationFrame(this.drawFrame);
		this.ticking = false;

		_.defer(()=>{
			this.CargoEditor.forcedRange= null;

			this.CargoEditor.rangy.getSelection().removeAllRanges();				

			this.CargoEditor.events.trigger('cursor-activity', this.CargoEditor.getActiveEditor());
			this.dragStartHandled = false;
			this.dragDropHandled = false;
			this.inAdmin = false;
			this.fromAdmin = false;			
		})
	}

	getCursorPos = (e)=>{
		const cursorPos = {
			x: this.x,
			y: this.y,
		}

		if( e && !isNaN(e.clientX) && !isNaN(e.clientY) ) {
			cursorPos.x = e.clientX
			cursorPos.y = e.clientY;
		}

		return cursorPos;
	}

	getInsertionRangeAndDropTargetFromCoordinates =(e, editor)=>{

		const draggingMediaItems = this.draggedMediaItems.length > 0;
		let dropTarget = null;

		let x = this.x;
		let y = this.y;

		if(!editor){
			return;
		}		

		const editorEl = editor.getElement();

		if( e && !isNaN(e.clientX) && !isNaN(e.clientY) ) {
			x = e.clientX
			y = e.clientY;
		}

		let insertionRange = this.CargoEditor.helpers.createRangeAtCoordinates(x, y);
		let editorRect = editorEl.getBoundingClientRect();
		let pointerInEditor = (
			x <=editorRect.left+editorRect.width && 
			x >=editorRect.left &&
			y <=editorRect.top+editorRect.height && 
			y >=editorRect.top
		);

		// if we're in the shadow root of some element, crawl up
		let container = insertionRange.commonAncestorContainer || null;
		while(!FRONTEND_DATA.contentWindow.document.contains(container)){
			container = container.getRootNode().host
		}

		if( container !== insertionRange.commonAncestorContainer){
			insertionRange.selectNodeContents(container);
		}

		// Safari does not create ranges next to items with shadow dom
		// so we have to do it manually in case it's off-base
		const closestColumnUnit = e.target.closest?.('column-unit');
		if( closestColumnUnit && !closestColumnUnit.contains(insertionRange.commonAncestorContainer) ){
			insertionRange.selectNodeContents(closestColumnUnit);
		}

		// if range creation fails (see safari), do a double-check on the target node
		if ( insertionRange.commonAncestorContainer === FRONTEND_DATA.contentWindow.document ){
			if( e.target){
				insertionRange = this.CargoEditor.rangy.createRange();
				insertionRange.selectNodeContents(e.target);
			} else {
				let target = FRONTEND_DATA.contentWindow.document.elementFromPoint(x, y);
				if( target ){
					insertionRange.selectNodeContents(target);
				}
			}
		} else if (
			insertionRange.startContainer === editorEl &&
			insertionRange.commonAncestorContainer === editorEl &&
			insertionRange.endContainer === editorEl &&
			draggingMediaItems
		){

			let target = insertionRange.startContainer.childNodes[insertionRange.startOffset];
			if( target && target.nodeName.startsWith('GALLERY-') && pointerInEditor){
				
				insertionRange = this.CargoEditor.rangy.createRange();
				insertionRange.selectNodeContents(target);
			}
		}

		// start by testing that the new range is inside the editor
		if( insertionRange && !editorEl.contains(insertionRange.commonAncestorContainer) ){
			
			if(
				y <= editorRect.top+editorRect.height*.5
			){
				insertionRange.setStart(editorEl, 0)
				insertionRange.setEnd(editorEl, 0)

			} else {

				insertionRange.setStart(editorEl, editorEl.childNodes.length);
				insertionRange.setEnd(editorEl, editorEl.childNodes.length);

			}
		}

		// then look for closest non-editable element, if it exists
		let closestNonEditable = insertionRange.commonAncestorContainer.nodeType === Node.TEXT_NODE ? insertionRange.commonAncestorContainer.parentNode.closest('[contenteditable="false"], [thumbnail-index], text-icon') : insertionRange.commonAncestorContainer.closest('[contenteditable="false"], [thumbnail-index]')

		if( closestNonEditable ){
			let referenceRect = closestNonEditable.getBoundingClientRect();
			if(
				y <= referenceRect.left+referenceRect.width*.5
			){
				insertionRange.setStartBefore(closestNonEditable)
				insertionRange.setEndBefore(closestNonEditable)
			} else {

				insertionRange.setStartAfter(closestNonEditable)
				insertionRange.setEndAfter(closestNonEditable)
			}					
		}

		// look for media figures to insert before or after
		let closestMediaItem = insertionRange.commonAncestorContainer.nodeType === Node.TEXT_NODE ? insertionRange.commonAncestorContainer.parentElement.closest('media-item, text-icon') : insertionRange.commonAncestorContainer.closest('media-item, text-icon')
		if( closestMediaItem ){


			let figCaption = closestMediaItem.querySelector('figcaption');


			// place media items outside of other media items
			// we don't know what stuff from the "outside" is, so we assume it can't be placed inside a media item
			if ( draggingMediaItems || this.fromOutside || (!draggingMediaItems && !figCaption) ){

				let referenceRect = closestMediaItem.getBoundingClientRect();

				if ( x < referenceRect.left + referenceRect.width * 0.5 ){
					insertionRange.setStartBefore(closestMediaItem)
					insertionRange.setEndBefore(closestMediaItem)
				} else {
					insertionRange.setStartAfter(closestMediaItem)
					insertionRange.setEndAfter(closestMediaItem)
				}

				dropTarget = closestMediaItem;

			// otherwise, find the caption element
			} else if (figCaption) {


				if( figCaption.classList.contains('empty')){
					insertionRange.setStart(figCaption, 0);
					insertionRange.setEnd(figCaption, 0);
				} 
				
				dropTarget = figCaption;


			}

		} else{			

			if( pointerInEditor){
				
				dropTarget = insertionRange.startContainer.childNodes[insertionRange.startOffset];
			} else {

				// put insertion at beginning or end, depending on where-out-of-bounds we are
				let insertionAtEnd = true;
				if( y <= editorRect.top + editorRect.height && y >= editorRect.top ){

					if ( x < editorRect.left + editorRect.width * 0.5 ){
						insertionAtEnd = false;
					} else {
						insertionAtEnd = true;
					}

				}  else {

					if ( y < editorRect.top + editorRect.height * 0.5 ){
						insertionAtEnd = false;
					} else {
						insertionAtEnd = true;
					}
				}


				if ( insertionAtEnd ){

					insertionRange.setStart(editorEl, editorEl.childNodes.length);
					insertionRange.setEnd(editorEl, editorEl.childNodes.length);

				} else {

					insertionRange.setStart(editorEl, 0);
					insertionRange.setEnd(editorEl, 0);

				}				
			}

		}


		// look for links
		let closestLink = insertionRange.commonAncestorContainer.nodeType === Node.TEXT_NODE ? insertionRange.commonAncestorContainer.parentElement.closest('a') : insertionRange.commonAncestorContainer.closest('a')

		// make sure that we aren't inserting a link in link
		if( closestLink && this.dragContainsLinks){

			// if the link is contained within a media figure, then move the target up to the media figure
			if( closestMediaItem && closestMediaItem.contains(closestLink) ){
				closestLink = closestMediaItem
			}

			let referenceRect = closestLink.getBoundingClientRect();

			if ( x < referenceRect.left + referenceRect.width * 0.5 ){
				insertionRange.setStartBefore(closestLink)
				insertionRange.setEndBefore(closestLink)
			} else {
				insertionRange.setStartAfter(closestLink)
				insertionRange.setEndAfter(closestLink)
			}
		}

		return {insertionRange, dropTarget}
	}

	setCustomDragImage = (e, options)=>{

		options = options || {}

		// remove pre-existing custom drag images....
		if( this.dragImage.custom && this.dragImage.el){
			this.dragImage.el.remove();
			this.dragImage.el = null;
			this.dragImage.custom = false;
		}

		let {
			el,
			rect
		} = options;
		
		if(el && rect) {

			const customDragImage = el.cloneNode(true);

			customDragImage.style.pointerEvents = 'none';
			Array.from(customDragImage.querySelectorAll('*')).forEach((el)=>{
				el.style.pointerEvents = 'none';
			})	
			customDragImage.style.width = rect.w+'px';
			customDragImage.style.height = rect.h+'px';
			customDragImage.style.position = 'fixed';
			customDragImage.style.marginLeft = -rect.x+'px';
			customDragImage.style.marginTop = -rect.y+'px';
			customDragImage.style.top = 0;
			customDragImage.style.left = 0;
			customDragImage.style.zIndex = 1001;
			customDragImage.style.transform = `translate(${this.x}px, ${this.y}px)`;
			customDragImage.style.transition = 'transform 16ms linear';

			FRONTEND_DATA.contentWindow.document.body.appendChild(customDragImage)
			this.dragImage = {
				el: customDragImage,
				custom: true,
				rect
			}

		}

		// override native drag image if it's possible
		// this currently doesn't appear to be working at all in safari...

		if( e?.dataTransfer?.setDragImage ){
			const img = this.blankImg.cloneNode();
			img.style.position = 'fixed';
			img.style.top = 0;
			img.style.left = 0;
			document.body.appendChild(img);
			e.dataTransfer.setDragImage?.(img, 0 , 0);
			setTimeout(()=>{
				img.remove();	
			}, 100)
			
		}	
	}

	edgeScroll = (timestamp)=>{
		cancelAnimationFrame(this.edgeScrollFrame);

		const delta = this.lastTimestamp == 0 ? 0 : timestamp - this.lastTimestamp;		
		const edgeSize = 40;
		const scrollAmt = 0;
		const maxScrollSpeed = 80;
		let percentage = 0;
		let dir = 'neutral';
		if ( this.y < edgeSize) {
			percentage = 1- this.y/edgeSize;
			dir = 'up'
		} else if ( this.y > this.windowHeight - edgeSize){
			percentage = 1 - (this.windowHeight - this.y)/edgeSize;
			dir = 'down'
		}

		// set it on a curve
		percentage = percentage*percentage

		let currentScroll = this.scrollParent.scrollTop 
		if ( dir !== 'neutral'){
			const movement = (delta/16.667)*percentage*maxScrollSpeed ;
			this.scrollParent.scrollTop = currentScroll + (dir =='down' ? movement : -movement);
		}

		this.lastTimestamp = timestamp;

		this.edgeScrollFrame = requestAnimationFrame(this.edgeScroll)
	}


	setDraggedNodes =(draggedNodes)=>{
		// adding arbitrary nodes to drag event invalidates the dragRange, so we set it to null
		this.dragRange = null;
		this.draggedNodes = draggedNodes;
		this.draggedMediaItems = draggedNodes.filter(node=>node.nodeName ==='MEDIA-ITEM');
		this.dragContainsLinks = draggedNodes.find(node=>node.nodeName ==='A' || node?.querySelector?.('A') ) ? true : false;

	}	

	releaseCustomDropTarget =(customDropTarget = this.customDropTarget)=>{
		if( this.customDropTarget && this.customDropTarget === customDropTarget){
			this.customDropTarget = null;
		}
	}	

	setCustomDropTarget =(customDropTarget)=>{
		if( !this.customDropTarget ){
			this.customDropTarget = customDropTarget	
		}
	}

	checkForEditorAndAdminMode = ()=>{
		return ( this.CargoEditor && this.CargoEditor.getActiveEditor() && this.adminMode)
	}

}

export default new DragEventController();