import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'
import { Encoder } from 'lib0/encoding'
import type {
  Event,
  CloseEvent,
  MessageEvent,
} from 'ws'
import { AuthenticationMessage } from './OutgoingMessages/AuthenticationMessage.js'
import { AwarenessMessage } from './OutgoingMessages/AwarenessMessage.js'
import { QueryAwarenessMessage } from './OutgoingMessages/QueryAwarenessMessage.js'
import { SyncStepOneMessage } from './OutgoingMessages/SyncStepOneMessage.js'
import { SyncStepTwoMessage } from './OutgoingMessages/SyncStepTwoMessage.js'
import { UpdateMessage } from './OutgoingMessages/UpdateMessage.js'
import { IncomingMessage } from './IncomingMessage.js'
import { OutgoingMessage } from './OutgoingMessage.js'

/**
 * State of the WebSocket connection.
 * https://developer.mozilla.org/de/docs/Web/API/WebSocket/readyState
 */
export enum WsReadyStates {
  Connecting = 0,
  Open = 1,
  Closing = 2,
  Closed = 3,
}

export enum MessageType {
  Sync = 0,
  Awareness = 1,
  Auth = 2,
  QueryAwareness = 3,
  Stateless = 5,
}

export enum WebSocketStatus {
  Connecting = 'connecting',
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export interface OutgoingMessageInterface {
  encoder: Encoder
  type?: MessageType
}

export interface OutgoingMessageArguments {
  token: string,
  document: Y.Doc,
  awareness: Awareness,
  clients: number[],
  states: Map<number, { [key: string]: any; }>,
  update: any,
  payload: string,
  encoder: Encoder,
}

export interface Constructable<T> {
  new(...args: any) : T
}

export type ConstructableOutgoingMessage =
  Constructable<AuthenticationMessage> |
  Constructable<AwarenessMessage> |
  Constructable<QueryAwarenessMessage> |
  Constructable<SyncStepOneMessage> |
  Constructable<SyncStepTwoMessage> |
  Constructable<UpdateMessage>

export type onAuthenticationFailedParameters = {
  reason: string,
}

export type onOpenParameters = {
  event: Event,
}

export type onMessageParameters = {
  event: MessageEvent,
  message: IncomingMessage,
}

export type onOutgoingMessageParameters = {
  message: OutgoingMessage,
}

export type onStatusParameters = {
  status: WebSocketStatus,
}

export type onSyncedParameters = {
  state: boolean,
}

export type onDisconnectParameters = {
  event: CloseEvent,
}

export type onCloseParameters = {
  event: CloseEvent,
}

export type onAwarenessUpdateParameters = {
  states: StatesArray
}

export type onAwarenessChangeParameters = {
  states: StatesArray
}

export type onStatelessParameters = {
  payload: string
}

export type onStatelessTaskParameters = onStatelessParameters & {
  task: StatelessTask,
  messageId: string,
  details?: JSON,
}

export enum StatelessTask {
  Ping = 'ping',
  Pong = 'pong',
  Publish = 'publish',
  Discard = 'discard',
  SaveAs = 'save-as',
}

export type StatelessTaskHandler = (data: onStatelessTaskParameters) => void

export type StatesArray = { clientId: number, [key: string | number]: any }[]
