/**
 * @module bindings/dom
 */

import * as Y from "yjs";
import { defaultFilter } from "./filter.js";

/**
 * @callback DomFilter
 * @param {string} nodeName
 * @param {Map<string, string>} attrs
 * @return {Map | null}
 */

/**
 * Creates a Yjs type (YXml) based on the contents of a DOM Element.
 *
 * @function
 * @param {Element|Text} element The DOM Element
 * @param {?Document} _document Optional. Provide the global document object
 * @param {Object<string, any>} [hooks = {}] Optional. Set of Yjs Hooks
 * @param {DomFilter} [filter=defaultFilter] Optional. Dom element filter
 * @param {?DomBinding} binding Warning: This property is for internal use only!
 * @return {Y.XmlElement | Y.XmlText | false}
 */
export const convertDomToType = (
	element,
	_document = document,
	hooks = {},
	filter = defaultFilter,
	binding
) => {
	/**
	 * @type {any}
	 */
	let type = null;
	if (element instanceof Element || element.nodeType === Node.ELEMENT_NODE) {
		let hookName = null;
		let hook;
		// configure `hookName !== undefined` if element is a hook.
		if (element.hasAttribute("data-yjs-hook")) {
			hookName = element.getAttribute("data-yjs-hook");
			hook = hooks[hookName];
			if (hook === undefined) {
				console.error(
					`Unknown hook "${hookName}". Deleting yjsHook dataset property.`
				);
				element.removeAttribute("data-yjs-hook");
				hookName = null;
			}
		}
		if (hookName === null) {

			if(!binding.nodeFilter(element)){
				type = false
			} else {
				type = new Y.XmlElement(element.nodeName);

				for (let i = element.attributes.length - 1; i >= 0; i--) {
					const attr = element.attributes[i];

					if ( !binding.attributeFilter(element, attr.name) ) {
						continue;
					}
					type.setAttribute(attr.name, attr.value);

				}
				type.insert(
					0,
					domsToTypes(
						element.childNodes,
						document,
						hooks,
						filter,
						binding
					)
				);
			}
		} else {
			// Is a hook
			type = new Y.XmlHook(hookName);
			hook.fillType(element, type);
		}
	} else if (element instanceof Text || element.nodeType === Node.TEXT_NODE || element.nodeType === Node.COMMENT_NODE) {
		
		type = new Y.XmlText();

		if(element.nodeType === Node.COMMENT_NODE) {
			type.setAttribute('nodeType', Node.COMMENT_NODE);
		}

		type.insert(0, element.nodeValue);
	} else {
		throw new Error("Can't transform this node type to a YXml type!");
	}

	createAssociation(binding, element, type);
	
	return type;
};

export const convertTypeToDom = (type, _document = document, hooks = {}, binding) => {

	let dom;

	type.doc.transact(() => {

		switch (type.constructor) {
			case Y.XmlElement: {

				switch(type.nodeName){
					case "polyline":
					case "path":
					case "rect":
					case "circle":
					case "ellipse":
					case "line":
					case "svg":
						// handle SVG elements
						dom = _document.createElementNS('http://www.w3.org/2000/svg', type.nodeName);
						break;
					default:
						// handle regular DOM elements
						dom = _document.createElement(type.nodeName);
				}
				
				// set attributes
				const attrs = type.getAttributes();

				for (const key in attrs) {
					dom.setAttribute(key, attrs[key]);
				}

				// handle children
				type.forEach.call(type, childYxml => {
					
					const childDom = childYxml.toDOM();

					// associate the child DOM
					createAssociation(binding, childDom, childYxml);

					dom.appendChild(childDom);

				})
				
				// associate the element DOM
				createAssociation(binding, dom, type)
				
				break;

			}
			case Y.XmlFragment:
			case Y.XmlText: {

				dom = type.toDOM();

				// associate the DOM node
				createAssociation(binding, dom, type)

				break;

			}
			case Y.XmlHook: {

				const hook = hooks[type.hookName];

				if (hook !== undefined) {
					dom = hook.createDom(type)
				} else {
					dom = document.createElement(type.hookName)
				}

				dom.setAttribute('data-yjs-hook', type.hookName)
				
				createAssociation(binding, dom, type);

				break;
				
			}
		}

	});

	return dom;

}

/**
 * Removes an association (the information that a DOM element belongs to a
 * type).
 *
 * @private
 * @function
 * @param {DomBinding} domBinding The binding object
 * @param {Element} dom The dom that is to be associated with type
 * @param {Y.XmlElement|Y.XmlHook} type The type that is to be associated with dom
 *
 */
export const removeAssociation = (domBinding, dom, type) => {

	//console.log('un-associate', dom, type);

	domBinding.domToType.delete(dom);
	domBinding.typeToDom.delete(type);
};

/**
 * Creates an association (the information that a DOM element belongs to a
 * type).
 *
 * @private
 * @function
 * @param {DomBinding} domBinding The binding object
 * @param {DocumentFragment|Element|Text} dom The dom that is to be associated with type
 * @param {Y.XmlFragment | Y.XmlElement | Y.XmlHook | Y.XmlText} type The type that is to be associated with dom
 *
 */
export const createAssociation = (domBinding, dom, type) => {

	//console.log('associate', dom, type);

	domBinding.domToType.set(dom, type);
	domBinding.typeToDom.set(type, dom);

	// check to see if we're waiting for this node to be associated
	const associationObserver = domBinding.domNodesAwaitingAssociation.get(dom);

	if(associationObserver) {
		// resolve the promise with the associated type
		associationObserver.resolve(type);
	}

	if(domBinding.onNewNodeAssociated) {
		domBinding.onNewNodeAssociated(dom, type);
	}
};

/**
 * Iterates items until an undeleted item is found.
 *
 * @private
 */
export const iterateUntilUndeleted = (item) => {
	while (item !== null && item.deleted) {
		item = item.right;
	}
	return item;
};

/**
 * Insert Dom Elements after one of the children of this YXmlFragment.
 * The Dom elements will be bound to a new YXmlElement and inserted at the
 * specified position.
 *
 * @private
 * @function
 * @param {YXmlElement} type The type in which to insert DOM elements.
 * @param {YXmlElement|null} prev The reference node. New YxmlElements are
 *                           inserted after this node. Set null to insert at
 *                           the beginning.
 * @param {Array<Element>} doms The Dom elements to insert.
 * @param {?Document} _document Optional. Provide the global document object.
 * @param {DomBinding} binding The dom binding
 * @return {Array<YXmlElement>} The YxmlElements that are inserted.
 */
export const insertDomElementsAfter = (
	type,
	prev,
	doms,
	_document,
	binding
) => {

	const types = domsToTypes(
		doms,
		_document,
		binding.opts.hooks,
		binding.filter,
		binding
	);

	type.insertAfter(prev, types);

	return types;
};

export const stringifyNodeIfTagNameIsIllegal = (node) => {

	if(node.nodeType === Node.ELEMENT_NODE) {
		try {
			document.createElement(node.tagName);
		} catch(e) {
			console.error(e);
			console.log('Replacing the following node with a text-only representation:', node);
			const replacementNode = document.createTextNode(node.outerHTML);
			node.replaceWith(replacementNode);
			node = replacementNode;
		}
	}

	return node

}

export const domsToTypes = (doms, _document, hooks, filter, binding) => {
	const types = [];
	for (let dom of doms) {
		if(binding.nodeFilter(dom)) {
			dom = stringifyNodeIfTagNameIsIllegal(dom);
			const t = convertDomToType(dom, _document, hooks, filter, binding);
			if (t !== false) {
				types.push(t);
			}
		}
	}

	return types;
};

/**
 * @private
 * @function
 */
export const insertNodeHelper = (
	yxml,
	prevExpectedNode,
	child,
	_document,
	binding
) => {
	let insertedNodes = insertDomElementsAfter(
		yxml,
		prevExpectedNode,
		[child],
		_document,
		binding
	);

	if (insertedNodes && insertedNodes.length > 0) {
		return insertedNodes[0];
	} else {
		return prevExpectedNode;
	}
};

/**
 * Remove children until `elem` is found.
 *
 * @private
 * @function
 * @param {Element} parent The parent of `elem` and `currentChild`.
 * @param {Node} currentChild Start removing elements with `currentChild`. If
 *                               `currentChild` is `elem` it won't be removed.
 * @param {Element|null} elem The elemnt to look for.
 */
export const removeDomChildrenUntilElementFound = (
	parent,
	currentChild,
	elem
) => {
	while (currentChild && currentChild !== elem) {
		const del = currentChild;
		currentChild = currentChild.nextSibling;
		let parentNodeIncludesDel = false;

		// this might need to be updated so that the child is always removed from the dom
		// even when it is not the direct descendant of the parent
		for(let child of parent.childNodes.values()){
			if(child === del) {
				parentNodeIncludesDel = true;
			}
		};
	
		if(parentNodeIncludesDel){
			parent.removeChild(del);
		}
	}
};

/**
 * Deletes a child type of a given dom parent, removes any associations.
 * @param {DomBinding} binding 
 * @param {Node} parent 
 * @param {YXmlElement | YXmlText | YXmlFragment} yxml 
 * @param {Node} dom 
 */
export const removeChildHelper = (binding, parent, yxml, dom) => {

	const yParent = binding.domToType.get(parent);

	if(yParent){
		yParent.toArray().forEach((childType, i) => {
			if(yxml === childType){
				yParent.delete(i);
				removeAssociation(binding, dom, yxml);
			}
		});
	}
}

export const getAllDescendants = (node, callback) => {

	// recursively finds all childnodes and fires a callback for every node found
	for (let i = 0; i < node.childNodes.length; i++) {
		callback(node.childNodes[i])
		// recursively go down the tree.
		getAllDescendants(node.childNodes[i], callback);
	}

}



