export default class IdleObserver {

	static IDLE = 0;
	static ACTIVE = 1;

	static eventsIndicatingActivity = [
		'click',
		'mousemove',
		'mouseenter',
		'keydown',
		'scroll',
		'mousewheel',
		'touchmove',
		'touchstart'
	]

	constructor(options = {}) {

		if(!options.idleTimeout) {
			throw 'Cannot start idleObserver without "idleTimeout" option'; 
		}

		options.idleTimeoutWhenHidden = options.idleTimeoutWhenHidden ?? options.idleTimeout;
		
		this.options = options;
		this.running = false;
		this.state = IdleObserver.ACTIVE;

		this.idleTimeout = document.visibilityState === 'hidden' ? this.options.idleTimeoutWhenHidden : this.options.idleTimeout;

		this.onStateChange = options.onStateChange || (() => {});

		IdleObserver.eventsIndicatingActivity.forEach(evt => {
			window.addEventListener(evt, this.onActive);
			document.getElementById('client-frame').contentWindow.addEventListener(evt, this.onActive);
		});
		
		document.addEventListener("visibilitychange", this.handleVisibilityChange, false);

	}

	onActive = () => {

		if(!this.running) {
			return;
		}

		// update timestamp at which the user will be marked as idle
		this.idleTimestamp = Date.now() + this.idleTimeout;

		// set active state
		this.setState(IdleObserver.ACTIVE);

	}

	setState = newState => {

		if(this.state !== newState) {
			this.state = newState;
			this.onStateChange(this.state);
		}

	}

	start = () => {

		if(this.running) {
			return;
		}

		this.running = true;
		this.state = IdleObserver.ACTIVE;

		// set the initial idle timestamp
		this.idleTimestamp = Date.now() + this.idleTimeout

		// check if we've reached our idle timeout every second
		this.idleCheckInterval = setInterval(this.checkAway, 1000);

	}

	stop = () => {

		if(!this.running) {
			return;
		}

		this.running = false;

		// kill event listeners
		IdleObserver.eventsIndicatingActivity.forEach(evt => {
			window.removeEventListener(evt, this.onActive);
			document.getElementById('client-frame').contentWindow.removeEventListener(evt, this.onActive);
		});

		document.removeEventListener("visibilitychange", this.handleVisibilityChange);

		clearInterval(this.idleCheckInterval);

	}

	checkAway = () => {

		if(!this.running) {
			return;
		}

		if (Date.now() < this.idleTimestamp) {
			// we haven't reached the idle timestamp yet
			this.setState(IdleObserver.ACTIVE);
			return;
		}

		this.setState(IdleObserver.IDLE);
	}

	handleVisibilityChange = () => {

		// update idle timeout based on document visibility 
		this.idleTimeout = document.visibilityState === 'hidden' ? this.options.idleTimeoutWhenHidden : this.options.idleTimeout;

		//console.log('document is:', document.visibilityState === 'hidden' ? 'hidden.' : 'visible.', 'using idle timeout of', this.idleTimeout);

		// Visibility change counts as activity and 
		// onActive will also apply the new timeout
		this.onActive();

	}

};