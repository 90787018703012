import axios from 'axios';
import cargoFetch from '@cargo/fetch';
import { each, merge, isArray} from 'lodash';
import { PENDING, FULFILLED, REJECTED } from "../middleware/api"
import { API_ORIGIN, API_PROXY_ORIGIN, CRDT_SERVER_ORIGIN } from "@cargo/common";
import { getCustomFonts } from '../components/ui-kit/helpers';
import _ from 'lodash';

const actions = {};

const API_REQUEST_TIMEOUT = 30000;

const RESTfulActionTypes = {
	FETCH_ADMIN_LIST       : 'FETCH_ADMIN_LIST',
	FETCH_FONT_COLLECTION  : 'FETCH_FONT_COLLECTION',
	FETCH_DOMAINS          : 'FETCH_DOMAINS',
	QUERY_DOMAIN_LIST	   : 'QUERY_DOMAIN_LIST',
	FETCH_PERMISSIONS      : 'FETCH_PERMISSIONS',
	FETCH_USER_PERMISSIONS : 'FETCH_USER_PERMISSIONS',
	INVITE_EDITOR		   : 'INVITE_EDITOR',
	FETCH_USER			   : 'FETCH_USER',
	FETCH_COLLAB           : 'FETCH_COLLAB',
	UPDATE_USER_META       : 'UPDATE_USER_META',
	UPDATE_USER            : 'UPDATE_USER',
	FETCH_SUBSCRIPTIONS    : 'FETCH_SUBSCRIPTIONS',
}

// extend default action types with FULFILLED, PENDING and REJECTED statuses
each(RESTfulActionTypes, function(key, val, obj){

	obj[key + '_' + FULFILLED] 	= val + '_' + FULFILLED;
	obj[key + '_' + PENDING] 	= val + '_' + PENDING;
	obj[key + '_' + REJECTED] 	= val + '_' + REJECTED;

});

const actionTypes = merge({
	// UPDATE_SITE_DESIGN: 	'UPDATE_SITE_DESIGN',
	UPDATE_ADMIN_STATE: 	'UPDATE_ADMIN_STATE',
	UPDATE_UIWINDOW: 		'UPDATE_UIWINDOW',
	STORE_UIWINDOW_POS:     'STORE_UIWINDOW_POS',
	ADD_UIWINDOW: 			'ADD_UIWINDOW',
	DELETE_UIWINDOW: 		'DELETE_UIWINDOW',
	UPDATE_PAGE: 			'UPDATE_PAGE',
}, RESTfulActionTypes);

actions.fetchAdminList = function() {

	return function(dispatch, getState) {

		const state = getState();

		let PATH = `/pages/${state.site.id}/all`;

		let request = axios.get(API_ORIGIN + PATH, {
			use_auth: true
		});

		return dispatch({
			type: actionTypes.FETCH_ADMIN_LIST,
			payload: request
		});

	}

}

actions.addFontsToFontCollection = function(fontArray = [] ){

	return function(dispatch, getState) {

		
		const fontCollection = getState().fontCollection;
		let unprocessedCollection = fontCollection.unprocessedCollection;
		if(fontArray.length > 0 && fontCollection.hasFontCollection ){

			// filter out fonts already inside collection
			const fontsToAdd = [...fontArray].filter(customFont=>{
				return !fontCollection.unprocessedCollection.some((font)=>{
					return font.family_name == customFont.family_name && font.is_active
				});
			})

			// then add into the collections where appropriate:
			if( fontsToAdd.length > 0 ){
				unprocessedCollection = [...unprocessedCollection];

				fontsToAdd.forEach(font=>{
					unprocessedCollection.push(font);
				});
			}

		} 

		return dispatch({
			type: actionTypes.FETCH_FONT_COLLECTION_FULFILLED,
			payload: {
				data: unprocessedCollection
			}
		});

	}

}

actions.fetchFontCollection = function( withCustomFonts = false ) {

	return function(dispatch, getState) {

		let request = new Promise(async function(resolve, reject) {

			let unCachedForDev = CARGO_ENV !== 'production' ? '?'+Date.now() : '';
			const response = await axios.get(`${API_PROXY_ORIGIN}/fonts/list`+unCachedForDev)

			if (response.status == 200) {
				resolve(response);
			} else {
				reject(response);
			}
		  
		});

		return dispatch({
			type: actionTypes.FETCH_FONT_COLLECTION,
			payload: request,
			meta: {
				// This should almost always be true. I can't forsee
				// when we would want to not pull custom fonts, which will process everything.
				skipProcessing: withCustomFonts,
			}
		});

	}

}

actions.fetchCustomFontCollection = function( unprocessedFontCollection ) {

	return function(dispatch, getState) {

		let unprocessedCollection = unprocessedFontCollection || getState().fontCollection.unprocessedCollection;
		// Filter custom fonts out of the font collection
		unprocessedCollection = _.filter(unprocessedCollection, (font) => {
			return font.provider !== 'custom';
		});

		let request = new Promise(async function(resolve, reject) {

			let customFontsArr = await getCustomFonts();

			// Filter out any custom fonts that are already in the font collection
			customFontsArr = _.filter(customFontsArr, (customFont) => {
				return !_.find(unprocessedCollection, (font) => {
					// if someone has added eg. Roboto into their custom fonts
					// let it into the list even if we have it disabled on our end
					return font.family_name === customFont.family_name && font.is_active;
				})
			});

			let response = { 
				data: unprocessedCollection,
				status: 200 // throw graceful error handling out the window.
			};

			if( customFontsArr && !_.isEmpty(customFontsArr) ){
				response.data = response.data.concat( customFontsArr );
			}

			resolve(response);
		});

		return dispatch({
			type: actionTypes.FETCH_FONT_COLLECTION,
			payload: request,
			meta: {
				// we never skip processing for custom fonts
				// this will always be the end of a chain of font collection requests
				skipProcessing: false,
			}
		});

	}

}

actions.fetchDomains = function() {

	return function(dispatch, getState) {

		// https://api.dev.cargo.site/v1/users/{userId}/domains
		let request = axios.get(`${API_ORIGIN}/users/${getState().auth?.data?.id}/domains`, {
			use_auth: true
		});

		return dispatch({
			type: actionTypes.FETCH_DOMAINS,
			payload: request
		});

	}

}

actions.fetchUser = function(options = {}) {
	// return all the permissions the current authenticated user has.
	return function(dispatch, getState) {

		let request = options.request || axios.get(`${API_ORIGIN}/users/${getState().auth?.data?.id}`, {
			use_auth: true
		});

		return dispatch({
			type: actionTypes.FETCH_USER,
			payload: request
		});

	}

}

actions.fetchAccount = function() {
	
	// return u.cargo user object
	// Not needed here, but keeping it just incase we
	// have a need for this data in the future.

	return function(dispatch, getState) {

		const state = getState();
		const userId = state.auth.data?.id;
		
		let request = cargoFetch.get(`${API_ORIGIN}/users/${userId}/homepage`);

		return dispatch({
			type: actionTypes.FETCH_USER,
			payload: request
		});

	}

}

actions.updateUser = (changes) => {

	return function(dispatch, getState) {

		const state = getState();
		const userId = state.auth.data?.id;
		
		return dispatch({
			type: actionTypes.UPDATE_USER,
			payload: cargoFetch.put(`${API_ORIGIN}/users/${userId}`, changes)
		});

	};

}

actions.fetchCollaborators = function() {
	// return all the collaborators associated with the user
	return function(dispatch, getState) {
		// /users/{userId}/collaborators
		let request = axios.get(`${API_ORIGIN}/users/${getState().auth?.data?.id}/collaborators`, {
			use_auth: true
		});

		return dispatch({
			type: actionTypes.FETCH_COLLAB,
			payload: request
		});

	}

}

actions.updateAdminState = function(changes, options){

	return function(dispatch, getState) {

		return dispatch({
			type: actionTypes.UPDATE_ADMIN_STATE,
			payload: changes
		})

	}

}

actions.updateUserMeta = function(changes, options){

	return function(dispatch, getState) {
		let request = axios.put(
			`${API_ORIGIN}/users/${getState().auth?.data?.id}`,
			{meta: changes}, 
			{use_auth: true}
		);

		return dispatch({
			type: actionTypes.UPDATE_USER_META,
			payload: request,
			meta: changes
		})

	}

}

// actions.updateSiteDesign = function(changes, options){
// 
// 	return function(dispatch, getState) {
// 
// 		return dispatch({
// 			type: actionTypes.UPDATE_SITE_DESIGN,
// 			payload: changes
// 		})
// 
// 	}
// 
// }

actions.updatePage = function(id, changes, options){

	return function(dispatch, getState) {

		return dispatch({
			type: actionTypes.UPDATE_PAGE,
			payload: changes,
			meta: {
				id
			}
		})

	}

}

actions.addUIWindow = function(uiWindow, options){

	return async function(dispatch, getState) {

		// if we are passing a dynamic
		if(typeof uiWindow.component?.then === 'function') {

			// passed an import promise as component. Resolve the import
			const {default: resolvedComponent} = await uiWindow.component;

			// overwrite the import with it's result
			uiWindow.component = resolvedComponent;

		}


		uiWindow = _.defaults(uiWindow, {
			// group the uiWindow will be added to
			group: 'main',
			// options passed to the uiWindow's component
			props: {}
		});

		options = _.defaults(options, {
			// close a uiWindow when it's already opened.
			toggle: true,
			// close all others in the group
			removeGroup: true,
			// close all groups of a defined name (string)
			removeGroupByName: false,
			// close all other uiWindows in all groups
			removeAll: false
		})

		// generate an id if none is passed.
		if(!uiWindow.id) {
			uiWindow.id = `${uiWindow.component}@${uiWindow.group}`
		}

		const state = getState();

		// If toggle is enabled, we need to check if an instance of this uiWindow already exists. 
		if(options.toggle) {
			if(state.uiWindows.byId[uiWindow.id]) {
				return dispatch(
					actions.removeUIWindow(uiWindow.id)
				);
			}

		}

		let hasUnsavedCommerceChanges = window.Cargo?.Ecommerce?.View?.currentView?.saveButton?.saveEnabled === true || window.Cargo?.Ecommerce?.View?.currentView?.SaveButton?.saveEnabled === true;
		if( hasUnsavedCommerceChanges && ( options.removeAll || options.removeGroup || options.removeGroupByName )){
			// If there are unsaved commerce changes, we need to prompt the user to save before closing the window.
			// This event sends the "open ui window" info to the c2-c3 view controller, which will open the window after
			// the save modal is cleared.
			window.dispatchEvent(
				new CustomEvent('await-c3-ui-window-open', {
					detail: { uiWindow: uiWindow, options: options }
				})
			);
			return
		}

		if(options.removeAll) {
			
			// remove all uiWindows
			_.each(state.uiWindows.byId, (uiWindow, uiWindowID) => {
				// if the window doesn't explicity ignore removal when adding a new window
				if (uiWindow?.props?.requireDiscreteClose !== true) {
					return dispatch(
						actions.removeUIWindow(uiWindowID)
					);
				}
			});

		} else if(options.removeGroup) {

			// remove all uiWindows in the same group as the new uiWindow
			if(state.uiWindows.byGroup[uiWindow.group]) {

				state.uiWindows.byGroup[uiWindow.group].forEach(uiWindowID => {

					// First remove any child windows of the window we're about to close...
					_.each(state.uiWindows.byId, (uiWindowByID) => {
						if( uiWindowByID.props.invokeWindow === uiWindowID ){
							dispatch(
								actions.removeUIWindow( uiWindowByID.id )
							);
						}
					})

					dispatch(
						actions.removeUIWindow(uiWindowID)
					);
				});
			}

		}

		if (options.removeGroupByName) {

			// remove all uiWindows in the group matching the passed string
			if(state.uiWindows.byGroup[options.removeGroupByName]) {
				state.uiWindows.byGroup[options.removeGroupByName].forEach(uiWindowID => {
					dispatch(
						actions.removeUIWindow(uiWindowID)
					);
				});
			}

			if( state.uiWindows.byGroup['typography-modal'] ) {
				state.uiWindows.byGroup['typography-modal'].forEach(uiWindowID => {
					dispatch(
						actions.removeUIWindow(uiWindowID)
					);
				});
			}

		}

		if (typeof options.callback === 'function') {
			options.callback()
		}

		return dispatch({
			type: actionTypes.ADD_UIWINDOW,
			payload: uiWindow
		});

	}

}

const windowsNotEligibleForRestoration = [
	'color-palette',
	'font-picker',
	'commerce-window'
]

actions.removeUIWindow = function(idOrFilter, options){

	return function(dispatch, getState) {

		let uiWindows = [];

		if(typeof idOrFilter === "function") {

			uiWindows = _.filter(getState().uiWindows.byId, idOrFilter);

		} else {

			uiWindows.push(getState().uiWindows.byId[idOrFilter]);

			if(!uiWindows[0]) {
				// nothing to close
				return Promise.resolve();
			}

		}

		if(window.location.pathname === '/edit/preview') {
			// when in preview, only close windows that are not able to be restored
			// reliably. The rest we keep open and restore once we exit preview
			uiWindows = uiWindows.filter(uiWindow => windowsNotEligibleForRestoration.includes(uiWindow.id));
		}


		if( uiWindows.length > 0 ){
			// Find dragged windows
			let draggedWindows = _.filter( uiWindows, (uiWindow)=>{ return uiWindow.props.dragged === true });
			// Store window position on close
			dispatch( actions.storeUIWindowPosition( draggedWindows ) );

			// Check for windows that cannot be closed without passing validation
			let windowRequriesValidationBeforeClose = _.filter( uiWindows, (uiWindow)=>{ return uiWindow.props.requiresValidationBeforeClose === true });
			_.each(windowRequriesValidationBeforeClose, (currentWindow)=> {
				let validated = currentWindow.props.validationFunction();
				if( !validated ){
					uiWindows = _.filter( uiWindows, (uiWindow)=>{ return uiWindow.id !== currentWindow.id && uiWindow.id !== currentWindow.props.invokeWindow });
				}
			}) 
		} else {
			// nothing to delete
			return;
		}

		if( uiWindows?.[0]?.id === 'subscription-window' ){
			let processingTransaction = document.querySelector('.payment-button .submit-payment.processing-transaction');
			if( processingTransaction ) return 
		}

		return dispatch({
			type: actionTypes.DELETE_UIWINDOW,
			payload: uiWindows
		});

	}

}

actions.updateUIWindow = function(uiWindowID, propsToMerge){

	return function(dispatch, getState) {

		return dispatch({
			type: actionTypes.UPDATE_UIWINDOW,
			payload: propsToMerge,
			meta: {
				id: uiWindowID
			}
		})

	}

}

actions.storeUIWindowPosition = function(uiWindows){

		return function(dispatch, getState) {
			// Don't save the position of these specific windows
			let exemptWindows  = ['css-selector-window', 'color-palette', 'library-window', 'pin-settings-window', 'meta-tags-window', 'site-description-window']
			// Don't save the position of windows that fall under these group types
			let exemptGroups   = ['focus-modal', 'fixed-pane']

			_.each( uiWindows, (uiWindow) => {
					// Stop if window is exempt from this type of positioning
					if(    exemptWindows.indexOf( uiWindow.id )          !== -1
					    || exemptGroups.indexOf( uiWindow?.props?.type ) !== -1 
					){
						return 
					}
					// Get ref, and position. Create empty object
					let ref = uiWindow.props.reference.current;

					if(!ref) {
						return;
					}

					let pos = ref.getBoundingClientRect();
					let windowPosObj = {}
					// assign coordinates from window position
					let coords = {
						x: pos.left,
						y: pos.top, 
						clientWidth: document.documentElement.clientWidth,
						clientHeight: document.documentElement.clientHeight 
					};
					// Create key: value that gets added to session storage
					windowPosObj[uiWindow.id] = coords;

					// Dispatch action...
					return dispatch({
						type: actionTypes.STORE_UIWINDOW_POS,
						payload: windowPosObj
					})
			})

		}

}

actions.fetchSubscriptions = function() {

	return function(dispatch, getState) {

		const state = getState();
		const userId = state.auth.data?.id;

		const request = cargoFetch.get(`${API_ORIGIN}/subscription/${userId}`)
		// const request = https://api.dev.cargo.site/v1/subscription/[user-id]
		return dispatch({
			type: actionTypes.FETCH_SUBSCRIPTIONS,
			payload: request
		})
	}

}


actions.undo = function() {

	return function(dispatch, getState) {

		return dispatch({
			type: '@@redux-undo/GLOBAL_UNDO'
		});

	}

}

actions.redo = function() {

	return function(dispatch, getState) {

		return dispatch({
			type: '@@redux-undo/GLOBAL_REDO'
		});

	}

}

export {
	actionTypes,
	actions
}