import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "./actions";
import { matchPath } from "react-router";
import { withRouter} from 'react-router-dom';
import { wsProvider } from "./lib/multi-user";

import { FRONTEND_DATA } from "./globals";

import _ from 'lodash';

export const overlayPaths = {
	COMMERCE: '/commerce/:param1?/:param2?/:param3?/:param4?'
}

export const paths = {
	ROOT_PATH: '/',
	PREVIEW: '/preview',
	PAGE_LIST: '/pages',
	EDIT_PAGE: '/:pid',
	DESIGN: '/design',
	SETTINGS: '/settings',
	...overlayPaths
}

class Router extends Component {

	constructor(props) {

		super(props);

		this.matchPaths(this.props.history.location.pathname);

		const ogHistoryPush = this.props.history.push;
		const ogHistoryReplace = this.props.history.replace;

		this.props.history.push = function() {

			if(window.commerceConfirmUnloadPromise) {

				const args = arguments;

				window.commerceConfirmUnloadPromise.then(() => {
					ogHistoryPush.apply(this, args);
				}).catch(() => {});

				return;

			}

			ogHistoryPush.apply(this, arguments);
		}

		this.props.history.replace = function() {
			if(window.commerceConfirmUnloadPromise) {

				const args = arguments;

				window.commerceConfirmUnloadPromise.then(() => {
					ogHistoryReplace.apply(this, args);
				}).catch(() => {});

				return;

			}

			ogHistoryReplace.apply(this, arguments);
		}

	}

	render() {

		return this.props.children;

	}

	componentDidUpdate(prevProps) {

		// Only match same path once
		if(this.lastHandledHistoryKey !== this.props.history.location.key) {
			this.matchPaths(this.props.history.location.pathname);
			this.lastHandledHistoryKey = this.props.history.location.key;
		}

	}

	matchPaths(pathname = this.props.history.location.pathname) {

		let match = null;

		if(
			match = matchPath(pathname, {
				path: paths.PREVIEW,
				exact: true
			})
		) { 

			this.storeMatchedPath(match);

			if(this.props.adminMode !== false) {

				// tell frontend we're not in admin mode
				// whilst previewing
				this.props.updateFrontendState({
					adminMode: false
				});

				// focus the content frame
				FRONTEND_DATA.contentWindow.focus();

				// remove old range if previously set
				this.lastRange = null;

				try {

					this.lastRange = CargoEditor.getActiveRange?.();
					
					// remove selection when going into preview
					FRONTEND_DATA.contentWindow.getSelection()?.removeAllRanges();

				} catch(e) {
					console.error(e);
				}

			}

			return;

		} else {

			if(this.props.adminMode !== true) {

				// restore range if possible
				if(this.lastRange) {
					try {
						CargoEditor.helpers.setActiveRange(this.lastRange);
					} catch(e) {
						console.error(e);
					}
				}
				
				// re-enable admin mode
				this.props.updateFrontendState({
					adminMode: true
				});

			}

		}

		if(
			match = matchPath(pathname, {
				path: paths.COMMERCE,
				exact: true
			})
		) { 

			this.storeMatchedPath(match);

			return;

		}

		if(
			match = matchPath(pathname, {
				path: paths.EDIT_PAGE,
				exact: true
			})
		) { 

			this.storeMatchedPath(match);

			return;

		}

		this.storeMatchedPath(match);

	}

	storeMatchedPath(match = null) {

		if(!_.isEqual(match, this.props.matchedRoute)) {

			this.onRouteChange(this.props.matchedRoute, match);

			// update the admin state with the new path	
			this.props.updateAdminState({
				matchedRoute: match
			});

			// set an attribute on the content window's 'body' tag to target via CSS
			// let adminLocationAttr = match && _.get(match, 'params.pid') ? 'page' : 'root';
			// FRONTEND_DATA.contentWindow.document.body.setAttribute('admin-view', adminLocationAttr)

		}

	}

	routeBackFromCommerce() {

		if(!this.oldUrl) {

			// this has to be a dynamic import because the page-list-manager
			// uses CRDT data that won't exist at the time this router component
			// loads.
			import('./lib/page-list-manager').then(({getDefaultEditingPID}) => {
				getDefaultEditingPID().then(pidToEdit => {
					this.props.history.push(`/${(pidToEdit || "")}`);
				});
			});

		} else {
			this.props.history.push(this.oldUrl);
		}

	}

	onRouteChange(oldMatch, newMatch) {

		delete this.oldUrl;

		if(newMatch?.path === paths.COMMERCE) {

			this.oldUrl = oldMatch?.url;

			if(
				// This is the first URL we're loading (direct load to /edit/commerce)
				!this.oldUrl
				// and the frontend window isn't navigated to a valid destination yet
				&& FRONTEND_DATA.history.location.pathname === '/client-side-rendering.html'
			) {
				// Make sure frontend is rendering something by navigating to home
				FRONTEND_DATA.history.replace('/');
			}

			// commerce was opened or updated
			if(oldMatch?.path !== paths.COMMERCE) {

				let otherEditor = null;
				const editors = store.getState().site.editors || [];
				const currentUserId = store.getState().user.id;
				const currentEditor = editors.find(editor => editor.id === currentUserId);

				// always allow support access to Commerce and do not block
				// access for others when support has Commerce open
				if(currentEditor?.role !== 'Support') {

					wsProvider.awareness.getStates().forEach(state => {

						if(
							// this state is using commerce
							state?.hasCommerceOpen === true
							// and is not our own state
							&& state !== wsProvider.awareness.getLocalState()
						) {
							otherEditor = editors.find(editor => editor.id === state.user.cargoId)
						}

					});

					// in use elsewhere
					if(otherEditor) {

						// wait a beat so the button doesn't stay active
						requestAnimationFrame(() => {
							this.routeBackFromCommerce();
						});

						return;

					}

					// claim commerce
					if(wsProvider.awareness.getLocalState()?.hasCommerceOpen !== true) {
						wsProvider.setAwarenessField('hasCommerceOpen', true);
					}

				}

				// new match
				this.props.addUIWindow({
					group: 'router-overlays',
					component: import('./components/right-menu-bar/commerce-window'),
					id: 'commerce-window',
					props: {
						type: 'fixed-pane',
						windowName: 'commerce',
						positionType: 'fixed-right',
						legacyWindow: true,
						ignoreClickout: true,
						closeCallback: () => {

							// wait a tiny bit to update the route. This way
							// we can correctly detect if the window is still open
							// in the right-menu-bar.js button
							setTimeout(() => {
								if(this.props.matchedRoute?.path === paths.COMMERCE) {
									this.routeBackFromCommerce();
								}
							});

						}
					}
				}, {
					removeAll: true 
				});

			} else {
				// Same route but different path (commerce/settings -> commerce/products)
			}

		} else if(oldMatch?.path === paths.COMMERCE) {

			if(wsProvider.awareness.getLocalState()?.hasCommerceOpen !== false) {
				wsProvider.setAwarenessField('hasCommerceOpen', false);
			}

			// left the commerce path
			this.props.removeUIWindow(uiWindow => {
				return uiWindow.id === 'commerce-window';
			});

		}

	}

}

function mapReduxStateToProps(state) {

	return {
		matchedRoute: state.adminState.matchedRoute,
		adminMode: state.frontendState.adminMode
	}

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		updateAdminState: actions.updateAdminState,
		updateFrontendState: actions.updateFrontendState,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);

}

export default withRouter(
	connect(
		mapReduxStateToProps, 
		mapDispatchToProps
	)(Router)
);
