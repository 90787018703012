import { CRDTState } from '../../../../globals';
import convertStateToSharedType from './convertStateToSharedType';
import { Y, ydoc } from '../../';
import _ from 'lodash';

// This function converts an object to a YJS shareable type. We have to 
// deep clone everything because YJS doesn't like data to come in from a foreign document (our iframe).
// Because our Redux state is coming from both the admin and frontend contexts we just clone all objects
// to ensure YJS won't crash.

const applyChangesToYType = (targetYType, changes, origin) => {

	// a new type is a part of the CRDT that has not been saved to the DB yet
	let typeIsNew = false;
	let typeToCheck = targetYType;

	// check all parent types to see if they have a CRDT State of "New"
	while(typeToCheck) {

		if(
			typeToCheck instanceof Y.Map 
			&& typeToCheck.has('crdt_state') 
			&& typeToCheck.get('crdt_state') === CRDTState.New
		) {
			typeIsNew = true;
			break;
		}

		typeToCheck = typeToCheck.parent;
	}

	const recursivelyApply = (YItem, changes) => {

		if(!YItem) {
			console.log('Unable to apply changes to non-existing YItem:', changes);
			return;
		}

		_.forEach(changes, (changedValue, changedKey) => {

			if(_.isArray(changedValue)) {

				// overwrite the previoys data with the new array converted to a Y type
				YItem.set(changedKey, convertStateToSharedType(changedValue, new Y.Array(), undefined, origin)); 

				return;
			}

			if(_.isPlainObject(changedValue)) {

				// ensure the CRDT has a map at this key
				if(YItem.get(changedKey) instanceof Y.Map === false) {

					const newMapForKey = new Y.Map()

					// When a type is new, it's not backed by the API yet. This means
					// that we have to copy over all the data from the current object into the 
					// new Ytype otherwise it'll be permanently lost. When something is already coming from the
					// API, we just mask the changed values over the API result, resulting in no loss of data.
					if(typeIsNew && _.isPlainObject(YItem.get(changedKey))) {

						// copy over all data present in the pre-existing object into the new Y.Map
						convertStateToSharedType(YItem.get(changedKey), newMapForKey, undefined, origin);

					}

					YItem.set(changedKey, newMapForKey); 
				}

				recursivelyApply(YItem.get(changedKey), changedValue)

				return;
			}

			YItem.set(changedKey, changedValue);

		});

	}

	ydoc.transact(() => {
		recursivelyApply(targetYType, changes);
	}, origin);

	return targetYType;

}

export default applyChangesToYType;